import { ROUTES, ROUTES_REVERSE_LOOKUP } from "../typedefs/typedefs";
import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withTheme } from "../layouts/withTheme";
import { withStyles } from "@material-ui/core";
import { STEPS } from "../typedefs/typedefs";
import CircularProgress from "@material-ui/core/CircularProgress";

const stepHelperTexts = [
  "Zum Hochladen der Datei.",
  "Zum Mapping des Schemas.",
  "Zur Mitteilungserfassung.",
  "Zum Überprüfen der Eingaben.",
  "Den Vorgang nun abschließen."
];

const styles = theme => ({
  helperText: {
    textAlign: "center"
  },
  navigationContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    padding: theme.spacing(0, 3)
  }
});

class NavigationButtons extends Component {
  /**
   * go to previous step and update url
   */
  onBackButton = () => {
    const { history, location } = this.props;
    history.push(
      ROUTES[parseInt(ROUTES_REVERSE_LOOKUP[location.pathname]) - 1]
    );
  };

  /**
   * go to next step and update url
   */
  onNextButton = () => {
    const { history, location } = this.props;
    history.push(
      ROUTES[parseInt(ROUTES_REVERSE_LOOKUP[location.pathname]) + 1]
    );
  };

  /**
   * go to first step and update url
   */
  onResetButton = () => this.props.history.push(ROUTES[0]);

  render() {
    const {
      customHandler,
      disabled,
      excludeBack,
      classes,
      loading,
      location,
      finished,
      overwriteHelperText,
      helperText
    } = this.props;
    const activeStep = parseInt(ROUTES_REVERSE_LOOKUP[location.pathname]);
    return (
      <React.Fragment>
        <Container
          maxWidth="lg"
          className={classNames(
            classes.navigationContainer,
            "bk-upload-buttons"
          )}
        >
          <div className="bk-upload-buttoncontainer">
            <Button
              color="primary"
              size="large"
              disabled={activeStep === 0 || disabled}
              onClick={() => {
                this.onBackButton();
                if (customHandler !== undefined && !excludeBack) {
                  customHandler();
                }
              }}
              variant="contained"
            >
              Zurück
            </Button>

            <div className={classes.helperText}>
              <Typography variant="body2">
                {activeStep > 0 ? stepHelperTexts[activeStep - 1] : ""}
              </Typography>
            </div>
          </div>

          <div className="bk-upload-buttoncontainer">
            <Button
              color="primary"
              size="large"
              disabled={!finished || disabled}
              onClick={() => {
                if (activeStep !== STEPS.length - 1) {
                  this.onNextButton();
                }
                if (customHandler !== undefined) {
                  customHandler();
                }
              }}
              variant="contained"
            >
              {loading ? (
                <CircularProgress size={20} />
              ) : activeStep === STEPS.length - 1 ? (
                "Daten absenden"
              ) : (
                "Weiter"
              )}
            </Button>

            <div className={classes.helperText}>
              <Typography variant="body2">
                {activeStep < stepHelperTexts.length
                  ? overwriteHelperText
                    ? helperText
                    : stepHelperTexts[activeStep + 1]
                  : ""}
              </Typography>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default withTheme(withStyles(styles)(NavigationButtons));
