/**
 * Created by Nicolas Looschen - info@pikobytes.de on 27.06.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { withTheme } from "./withTheme";
import { withStyles } from "@material-ui/core";
import AppBar from "../components/AppBar/AppBar";
import LegalView from "../views/LegalView/LegalView";
import WelcomeView from "../views/WelcomeView/WelcomeView";
import "./AppLayout.scss";
import UploadToolLayout from "./UploadToolLayout/UploadToolLayout";
import LoginNoticeView from "../views/LoginNoticeView";
import LoginView from "../moduleAuth/views/LoginView/LoginView";
import { getCredentials } from "../util/authorization";
import ConsentView from "../views/ConsentView";
import FaqView from "../views/FaqView/FaqView";

const styles = theme => ({
  content: {
    display: "block",
    position: "absolute",
    top: 65,
    bottom: 0,
    left: 0,
    right: 0,
    paddingTop: theme.spacing(2),
    overflowY: "visible",
    overflowX: "hidden"
  },
  root: {
    overflowX: "hidden",
    display: "flex"
  }
});

class AppLayout extends Component {
  state = {
    consentGiven: false,
    user:
      getCredentials() === undefined
        ? { user: undefined, password: undefined }
        : getCredentials()
  };

  giveConsent = () => this.setState({ consentGiven: true });

  /**
   * handle credential update
   * @param {?} newCredentials
   */
  handleCredentialUpdate = newCredentials =>
    this.setState({ user: newCredentials });

  /**
   * handle user logout
   */
  handleLogout = () => {
    // After logout always perform an hard reload. Through performing the hard
    // reload with a fragment replace we make sure to load the application
    // without an old state.
    window.location.href = window.location.origin;
    this.resetStep();
  };

  render() {
    const { classes } = this.props;
    const { consentGiven, user } = this.state;
    return (
      <div className={classes.root}>
        <AppBar
          links={[
            {
              name: "PROJEKT",
              to: {
                pathname: "/"
              },
              exact: true
            },
            {
              name: "UPLOAD",
              to: {
                pathname: "/uploadTool"
              }
            },
            {
              name: "VIEWER",
              href: process.env.REACT_APP_LINK_DATAVIEWER,
              target: "_blank"
            },
            {
              name: "FAQ",
              to: {
                pathname: "/faq"
              }
            },
            {
              name: "LEGAL",
              to: {
                pathname: "/legal"
              }
            },
            {
              name: "LOGIN",
              loggedIn:
                user.password !== undefined && user.username !== undefined,
              to: {
                pathname: "/login"
              }
            }
          ]}
          title="BaumCLOUD"
        />
        <main className={classes.content}>
          <Switch>
            <Route exact path="/" component={WelcomeView} />
            <Route
              path="/uploadTool"
              render={props => (
                <UploadToolLayout
                  {...props}
                  user={this.state.user}
                  consentGiven={consentGiven}
                />
              )}
            />
            <Route path="/notice" component={LoginNoticeView} />
            <Route exact path="/faq" component={FaqView} />
            <Route
              path="/consent"
              render={props => (
                <ConsentView {...props} giveConsent={this.giveConsent} />
              )}
            />
            <Route exact path="/legal" component={LegalView} />
            <Route
              exact
              path="/login"
              render={() => (
                <LoginView
                  onLogout={this.handleLogout}
                  onSubmit={() => console.log("login")}
                  onUpdateCredentials={this.handleCredentialUpdate}
                  user={user}
                />
              )}
            />
          </Switch>
        </main>
      </div>
    );
  }
}

export default withTheme(withStyles(styles)(AppLayout));
