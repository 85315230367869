/**
 * Created by jacob.mendt@pikobytes.de on 24.04.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

// For defining better theme colors have a look at:
//
// https://material-ui.com/style/color/
// https://material.io/tools/color/#!/?view.left=0&view.right=1&primary.color=F77439&secondary.color=f4a13b
export const theme = createTheme({
  palette: {
    primary: {
      light: "#cae657",
      main: "#96b422",
      dark: "#648400",
      contrastText: "#000000",
      text: "#000000"
    },
    secondary: {
      light: "#ffe54b",
      main: "#f1b300",
      dark: "#b98400",
      contrastText: "#000000",
      text: "#000000"
    }
  },
  typography: {
    fontSize: 12,
    useNextVariants: true
  }
});

/**
 * Function add the basic styling
 * @param {Element} Component
 * @returns {Function}
 */
export function withTheme(Component) {
  function withTheme_(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return withTheme_;
}
