/**
 * Created by jacob.mendt@pikobytes.de on 27.06.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Link from "@material-ui/core/Link";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Container from "@material-ui/core/Container";
import { withTheme } from "../../layouts/withTheme";
import Logo from "./LogoBaumcloud.svg";
import "./AppBar.scss";

const styles = theme => {
  return {
    appBar: {
      position: "absolute",
      zIndex: 2000,
      height: 65,
      boxShadow: "0 1px 4px rgba(0,0,0,.3)",
      background: theme.palette.primary.main,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    title: {
      display: "none"
    },
    navlink: {
      color: theme.palette.primary.text
    }
  };
};

class DefaultAppBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleDropdown: false,
      toggleMenu: false,
      width: window.innerWidth
    };
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  /**
   * Called when a resize event occures. By using an Arrow Function we do not
   * have to assign this when using as event listener.
   */
  handleResize = () => {
    this.setState({ width: window.innerWidth });
  };

  /**
   * Closes the Menu. Only works if the width is smaller then the breakPoint.
   */
  handleCloseMenu = () => {
    const { width } = this.state;
    const { breakPoint } = this.props;

    if (width < breakPoint) {
      this.setState({ toggleMenu: false });
    }
  };

  /**
   * Toggles the Menu. Only works if the width is smaller then the breakPoint.
   */
  handleToggleMenu = () => {
    const { toggleMenu, width } = this.state;
    const { breakPoint } = this.props;

    if (width < breakPoint) {
      this.setState({ toggleMenu: !toggleMenu });
    }
  };

  renderDrawer() {
    const { links } = this.props;
    return (
      <div className="bk-appbar-drawer-inner">
        {links.map((link, index) => {
          return link.target !== undefined ? (
            <a
              key={index}
              className="bk-navlink"
              href={link.href}
            >
              <ListItem button>
                <ListItemText
                  primary={link.name.toUpperCase()}
                  className="bk-navlink-text"
                />
              </ListItem>
            </a>
          ) : (
            <NavLink
              key={index}
              className="bk-navlink"
              activeClassName="is-active"
              to={link.to}
              exact={link.exact}
            >
              <ListItem button>
                <ListItemText
                  primary={link.name.toUpperCase()}
                  className="bk-navlink-text"
                />
              </ListItem>
            </NavLink>
          );
        })}
      </div>
    );
  }

  render() {
    const { toggleMenu, width } = this.state;
    const { breakPoint, classes, links, title } = this.props;

    return (
      // If the app width is small than the breakPoint display a burger menu
      // instead of the normal navlinks
      <AppBar className={classNames(classes.appBar, "bk-appbar")}>
        <Container maxWidth="lg">
          <Toolbar className={classNames(classes.toolBar, "bk-toolbar")}>
            <Link href="/" className="bk-brand-container">
              <img src={Logo} alt="Logo" className="bk-logo" />
            </Link>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            {width < breakPoint && (
              <IconButton
                edge="end"
                className={classNames(classes.menuButton, "bk-menu-toggle")}
                color="inherit"
                aria-label="Toggle Menu"
                onClick={this.handleToggleMenu}
              >
                {toggleMenu ? <ClearIcon /> : <MenuIcon />}
              </IconButton>
            )}
            {width >= breakPoint &&
              links.map((link, index) => {
                return link.target !== undefined ? (
                  <a
                    key={index}
                    className={classNames(classes.navlink, "bk-navlink")}
                    href={link.href}
                    target={link.target}
                  >{link.name.toUpperCase()}</a>
                ) : (
                  <Link
                    key={index}
                    component={React.forwardRef((props, ref) => (
                      <NavLink innerRef={ref} {...props}/>
                    ))}
                    className={classNames(classes.navlink, "bk-navlink")}
                    activeClassName="is-active"
                    to={link.to}
                    exact={link.exact}
                  >
                    {!link.loggedIn ? (
                      link.name.toUpperCase()
                    ) : (
                      <AccountCircle />
                    )}
                  </Link>
                );
              })}
            {width < breakPoint && (
              <Drawer
                className="bk-appbar-drawer"
                anchor="top"
                open={toggleMenu}
                onClose={this.handleCloseMenu}
              >
                <div
                  tabIndex={0}
                  role="button"
                  onClick={this.handleCloseMenu}
                  onKeyDown={this.handleCloseMenu}
                >
                  {this.renderDrawer()}
                </div>
              </Drawer>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
}

DefaultAppBar.defaultProps = {
  auth: false,
  breakPoint: 1088,
  title: "Logo"
};

DefaultAppBar.propTypes = {
  auth: PropTypes.bool,
  breakPoint: PropTypes.number,
  classes: PropTypes.object.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: PropTypes.oneOfType([
        PropTypes.shape({
          pathname: PropTypes.string
        })
      ]),
      target: PropTypes.string,
      href: PropTypes.string,
    })
  ).isRequired,
  title: PropTypes.string
};

export default withTheme(withStyles(styles)(DefaultAppBar));
