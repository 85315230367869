/**
 * Created by jacob.mendt@pikobytes.de on 26.04.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

/**
 * @type {{PASSWORD: string, USERNAME: string}}
 */
const STORAGE_KEYS = {
  PASSWORD: "sh-basic-auth-password",
  USERNAME: "sh-basic-auth-username",
  SESSIONID: "sh-basic-sessionid"
};

/**
 * Resets the current authentification information in the sessionStorage.
 */
export function resetCredentials() {
  window.sessionStorage.setItem(STORAGE_KEYS.PASSWORD, "");
  window.sessionStorage.setItem(STORAGE_KEYS.USERNAME, "");
  window.sessionStorage.setItem(STORAGE_KEYS.SESSIONID, "");
}

/**
 * Returns the authorization header
 * @param {{ username: string, password: string }|undefined}
 * @returns {{ key: string, content: string }|undefined}
 */
export function getAuthHeader(optCredentials = undefined) {
  const credentials =
    optCredentials === undefined ? getCredentials() : optCredentials;
  return credentials !== undefined
    ? {
        key: "Authorization",
        content: btoa(`${credentials.username}:${credentials.password}`)
      }
    : undefined;
}

/**
 * @returns {{ password: string, username: string }|undefined}
 */
export function getCredentials() {
  const password = window.sessionStorage.getItem(STORAGE_KEYS.PASSWORD);
  const username = window.sessionStorage.getItem(STORAGE_KEYS.USERNAME);
  const credentials =
    password === null ||
    password.length === 0 ||
    (username === null || username.length === 0)
      ? undefined
      : { password: password, username: username };

  return credentials;
}

/**
 * Save the current basic auth credentials.
 * @param {{ password: string, username: string }}
 */
export function setCredentials({ password, username }) {
  window.sessionStorage.setItem(STORAGE_KEYS.PASSWORD, password);
  window.sessionStorage.setItem(STORAGE_KEYS.USERNAME, username);
}

export function setSessionId(sessionId) {
  window.sessionStorage.setItem(STORAGE_KEYS.SESSIONID, sessionId);
}
export function getSessionId() {
  const sessionId = window.sessionStorage.getItem(STORAGE_KEYS.SESSIONID);
  if (sessionId === null || sessionId.length === 0) {
    return undefined;
  }
  return sessionId;
}
