/**
 * Created by Nicolas Looschen - info@pikobytes.de on 05.07.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, { Component } from "react";
import classNames from "classnames";
import Paper from "@material-ui/core/Paper";
import { withTheme } from "../layouts/withTheme";
import { withStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";
import SubmitHeaderIcon from "@material-ui/icons/DoneAll";

import { API_ENDPOINT, DATABASE_FIELDS_DISPLAY } from "../typedefs/typedefs";
import MappingPreview from "../components/MappingPreview/MappingPreview";
import axios from "axios";
import {
  getCredentials,
  getSessionId,
  setSessionId
} from "../util/authorization";
import "./SubmitView.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import NavigationButtons from "../components/NavigationButtons";
import Notification from "../moduleAuth/components/Notification/Notification";

const styles = theme => ({
  button: {
    marginTop: 20
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150
  },
  mappingList: {
    marginBottom: 15
  },
  mappingPreview: {
    marginTop: 20
  },
  mappingScroll: {
    overflowY: "auto",
    height: 290
  },
  message: {
    marginBottom: 20
  },
  navigation: {
    marginTop: 20
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    margin: theme.spacing(0, 0, 5, 0)
  },
  paperContainer: {
    height: "95%",
    width: "100%",
    padding: theme.spacing(0, 3)
  },
  title: {
    marginBottom: 15,
    marginTop: 5
  }
});
class SubmitView extends Component {
  static propTypes = {
    data: PropTypes.array,
    finished: PropTypes.bool,
    mapping: PropTypes.object,
    message: PropTypes.string,
    metadata: PropTypes.object,
    submitted: PropTypes.bool
  };

  paperRef = React.createRef();

  state = {
    showError: false,
    width: 100
  };

  componentDidMount() {
    this.props.finishStep();
    if (this.paperRef.current) {
      this.setState({ width: this.paperRef.current.clientWidth });
    }
  }

  componentDidUpdate() {
    if (
      this.paperRef.current &&
      this.paperRef.current.clientWidth !== this.state.width
    ) {
      this.setState({ width: this.paperRef.current.clientWidth });
    }
  }

  submitMapping = () => {
    const { submit } = this.props;
    submit();
    this.setState({ loading: true });
    axios({
      method: "POST",
      url: `${API_ENDPOINT}/api/upload/${getSessionId()}/submit`,
      auth: getCredentials()
    })
      .then(() => {
        setSessionId("");
        this.props.history.push("completed");
      })
      .catch(e => {
        console.log(e);
        this.setState({ loading: false, showError: true });
      });
  };

  render() {
    const {
      classes,
      data,
      history,
      location,
      finished,
      mapping,
      message,
      metadata,
      submitted
    } = this.props;

    const { license, credits, reference_date, csv_mapping } = metadata;
    const { loading, showError, width } = this.state;

    return (
      <React.Fragment>
        <div className={classes.paperContainer} ref={this.paperRef}>
          <Paper
            className={classNames(classes.paper, "bk-upload-view-submit")}
            ref={this.paperRef}
          >
            <Typography variant="h4" className={classes.title}>
              <SubmitHeaderIcon />
              Überprüfen Sie Ihre Eingaben
            </Typography>
            <Typography className={classes.title} variant="h5">
              Metadaten
            </Typography>
            <FormGroup row className="metadata-container">
              <FormControl className={classes.formControl} disabled>
                <InputLabel htmlFor="license">License</InputLabel>
                <Select inputProps={{ id: "license" }} value={license}>
                  <MenuItem value={license}>{license}</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl} disabled>
                <InputLabel htmlFor="credit">Credits</InputLabel>

                <Select
                  inputProps={{ id: "credit" }}
                  value={credits === "" ? "empty" : credits}
                >
                  <MenuItem value={credits === "" ? "empty" : credits}>
                    {credits === "" ? "Keine Erwähnung angegeben" : credits}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl} disabled>
                <InputLabel htmlFor="date">Referenz Datum</InputLabel>

                <Select inputProps={{ id: "date" }} value={reference_date}>
                  <MenuItem value={reference_date}>{reference_date}</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl} disabled>
                <InputLabel htmlFor="encoding">Kodierung</InputLabel>

                <Select
                  inputProps={{ id: "encoding" }}
                  value={csv_mapping.charset}
                >
                  <MenuItem value={csv_mapping.charset}>
                    {csv_mapping.charset}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl} disabled>
                <InputLabel htmlFor="fieldseparator">Trennzeichen</InputLabel>

                <Select
                  inputProps={{ id: "fieldseparator" }}
                  value={
                    csv_mapping.field_separator === undefined
                      ? ""
                      : csv_mapping.field_separator
                  }
                >
                  <MenuItem value={csv_mapping.field_separator}>
                    {csv_mapping.field_separator}
                  </MenuItem>
                </Select>
              </FormControl>
            </FormGroup>
            <Divider />
            <Typography className={classes.title} variant="h5">
              Ausgewähltes Mapping
            </Typography>
            <Grid container>
              <Grid item xs={12} className={classes.mappingList}>
                <List component="ul">
                  <Grid container alignItems="center">
                    <ListItem component="li" divider>
                      <Grid item xs={6}>
                        <ListItemText
                          primary={
                            <Typography variant="h6">
                              Feld im Ausgangsschema
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <ListItemText
                          primary={
                            <Typography variant="h6">
                              Feld im Zielschema
                            </Typography>
                          }
                        />
                      </Grid>
                    </ListItem>
                  </Grid>
                  <div className={classes.mappingScroll}>
                    {Object.keys(mapping).map(entry => (
                      <Grid container alignItems="center" key={entry}>
                        <ListItem component="li" divider>
                          <Grid item xs={6}>
                            <ListItemText
                              primary={
                                <Typography variant="body1">{entry}</Typography>
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <ListItemText
                              primary={
                                <Typography variant="body1">
                                  {
                                    DATABASE_FIELDS_DISPLAY[
                                      mapping[entry].target
                                    ]
                                  }
                                </Typography>
                              }
                            />
                          </Grid>
                        </ListItem>
                      </Grid>
                    ))}
                  </div>
                </List>
              </Grid>
            </Grid>
            <Divider />
            <Typography className={classes.title} variant="h5">
              Anmerkungen
            </Typography>
            <div className={classes.message}>
              <TextField
                id="message"
                placeholder="Ansicht der hinterlegten Nachricht."
                fullWidth
                multiline
                disabled
                variant="outlined"
                value={
                  message !== ""
                    ? message
                    : "Sie haben keine Nachricht angegeben."
                }
              />
            </div>
            <Divider />
            <Typography className={classes.title} variant="h5">
              Erzeugtes Mapping
            </Typography>
            <div className={classes.mappingPreview}>
              {data.length !== 0 && (
                <MappingPreview
                  columnCount={data[0].length}
                  data={data}
                  height={400}
                  rowCount={data.length}
                  maxWidth={width - 96}
                />
              )}
            </div>
          </Paper>
        </div>
        <div>
          {loading && (
            <div className="bk-upload-loadingindicator">
              <span>Lade Daten</span>
              <CircularProgress size={42} />
            </div>
          )}
        </div>
        <NavigationButtons
          excludeBack
          disabled={submitted}
          customHandler={this.submitMapping}
          history={history}
          location={location}
          finished={finished}
        />

        {showError && (
          <Notification
            style={{
              position: "absolute",
              top: this.paperRef.current.clientHeight - 240,
              width: this.paperRef.current.clientWidth / 2,
              left:
                this.paperRef.current.clientWidth / 2 -
                this.paperRef.current.clientWidth / 4
            }}
            message={
              <div>
                Upload fehlgeschlagen. Bitte kontaktieren Sie{" "}
                <a href="mailto: support@baumcloud.org">
                  support@baumcloud.org
                </a>{" "}
                wenn der Fehler wiederholt auftritt.
              </div>
            }
            onClose={() =>
              this.setState({
                showError: false
              })
            }
            variant="error"
          />
        )}
      </React.Fragment>
    );
  }
}

export default withTheme(withStyles(styles)(SubmitView));
