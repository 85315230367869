/**
 * Created by jacob.mendt@pikobytes.de on 27.06.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper/index";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import "./LegalView.scss";

const styles = () => ({
  paper: {
    padding: 20,
    fontSize: "1rem"
  }
});

/**
 * Returns an string which signals the google analytics library to be disabled.
 * @returns {string}
 */
function getGaDisableStr() {
  return `ga-disable-${process.env.REACT_APP_GA_TOKEN}`;
}

/**
 * Checks if google analytics is enabled/disabled. Returns true if google analytics
 * is disabled.
 * @returns {boolean}
 */
function isGADisabled() {
  if (document.cookie.indexOf(`${getGaDisableStr()}=true`) > -1) {
    window[getGaDisableStr()] = true;
    return false;
  }
  return true;
}

/**
 * Disables the GA
 */
function disableGA() {
  document.cookie = `${getGaDisableStr()}=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`;
  window[getGaDisableStr()] = true;
}

/**
 * Page containing the privacy / legal information.
 */
class LegalView extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired
  };

  /**
   * @param {?} props
   */
  constructor(props) {
    super(props);

    this.state = {
      gaEnabled: isGADisabled()
    };
  }

  /**
   * Renders the components
   */
  render() {
    const { classes } = this.props;

    return (
      <Container maxWidth="lg">
        <Paper className={classNames(classes.paper, "bk-view-legal")}>
          <div>
            <Typography variant="h3">
              Allgemeine Hinweise und Informationen
            </Typography>
            <br />
            <Typography variant="h4">Datenschutzerklärung</Typography>
            <Typography variant="body1">
              Die Terra Concordia gGmbH nimmt den Schutz Ihrer persönlichen
              Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
              vertraulich und entsprechend der gesetzlichen
              Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn
              Sie diese Website benutzen, werden verschiedene personenbezogene
              Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
              persönlich identifiziert werden können. Die vorliegende
              Datenschutzerklärung erläutert, welche Daten wir erheben und wofür
              wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
              geschieht. Wir weisen darauf hin, dass die Datenübertragung im
              Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken
              aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff
              durch Dritte ist nicht möglich.
            </Typography>
            <br />
            <Typography variant="h5">Verantwortliche Stelle</Typography>
            <div className="indent box">
              <Typography variant="body2">Terra Concordia gGmbH</Typography>
              <Typography variant="body2">Kai Gildhorn</Typography>
              <Typography variant="body2">Bouchéstr. 79b</Typography>
              <Typography variant="body2">12435 Berlin</Typography>
              <Typography variant="body2">
                E-Mail: info@baumcloud.org{" "}
              </Typography>
            </div>
            <Typography variant="body1">
              Die verantwortliche Stelle ist die natürliche oder juristische
              Person, die allein oder gemeinsam mit anderen über die Zwecke und
              Mittel der Verarbeitung von personenbezogenen Daten entscheidet.
            </Typography>
            <Typography variant="h5">Wie erfassen wir Ihre Daten?</Typography>
            <Typography variant="body1">
              Ihre Baumkatasterdaten werden zum einen dadurch erhoben, dass Sie
              uns diese im Rahmen eines Uploads zur Verfügung stellen. Um Spam
              zu verhindern müssen Sie sich vor dem Hochladen Ihrer Daten mit
              Ihrer Emailadresse anmelden. Andere Daten werden automatisch beim
              Besuch der Webseite durch unsere IT-Systeme erfasst. Das sind vor
              allem technische Daten (z.B. Internetbrowser, Betriebssystem oder
              Uhrzeit des Seitenaufrufs).
            </Typography>
            <Typography variant="h5">
              Widerruf Ihrer Einwilligung zur Datenverarbeitung
            </Typography>
            <Typography variant="body1">
              Der Upload Ihrer Baumkatasterdaten ist nur mit Ihrer
              ausdrücklichen Einwilligung möglich, die Sie mit dem Abschicken
              der Daten abschließen. Sie können eine bereits erteilte
              Einwilligung jederzeit widerrufen. Dazu reicht eine formlose
              Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum
              Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
              unberührt.
            </Typography>
            <Typography variant="h5">
              Beschwerderecht bei der zuständigen Aufsichtsbehörde
            </Typography>
            <Typography variant="body1">
              Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein
              Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
              Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist
              der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser
              Unternehmen seinen Sitz hat, in diesem Fall also Berlin. Eine
              Liste der Datenschutzbeauftragten sowie deren Kontaktdaten können
              Sie folgendem Link entnehmen.
            </Typography>
            <Typography variant="h5">Recht auf Datenübertragbarkeit</Typography>
            <Typography variant="body1">
              Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
              Einwilligung oder in Erfüllung eines Vertrags automatisiert
              verarbeiten, an sich oder an einen Dritten in einem gängigen,
              maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die
              direkte Übertragung der Daten an einen anderen Verantwortlichen
              verlangen, erfolgt dies nur, soweit es technisch machbar ist.
            </Typography>
            <Typography variant="h5">TLS-Verschlüsselung</Typography>
            <Typography variant="body1">
              Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
              Übertragung vertraulicher Inhalte, wie zum Beispiel Anfragen, die
              Sie an uns als Seitenbetreiber senden, eine TLS-Verschlüsselung.
              Eine verschlüsselte Verbindung erkennen Sie daran, dass die
              Adresszeile des Browsers von “http://” auf “https://” wechselt und
              an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die
              TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an
              uns übermitteln, nicht von Dritten mitgelesen werden.
            </Typography>
            <Typography variant="h5">Auskunft, Sperrung, Löschung</Typography>
            <Typography variant="body1">
              Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
              jederzeit das Recht auf unentgeltliche Auskunft über Ihre
              gespeicherten personenbezogenen Daten, deren Herkunft und
              Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht
              auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu
              sowie zu weiteren Fragen zum Thema personenbezogene Daten können
              Sie sich jederzeit an die o.g. Adresse an uns wenden. Weiterhin
              steht Ihnen ein Beschwerderecht bei der zuständigen
              Aufsichtsbehörde zu.
            </Typography>

            <Typography variant="h5">
              Erfassung nicht personenbezogener Daten
            </Typography>
            <Typography variant="h6">Server-Log-Dateien</Typography>
            <Typography variant="body1">
              Der Provider der Webseite erhebt und speichert automatisch
              Informationen in sogenannten Server-Log-Dateien, die Ihr Browser
              automatisch an uns übermittelt. Dies sind:
            </Typography>
            <ul>
              <li>Browsertyp und Browserversion</li>
              <li>Betriebssystem</li>
              <li>Referrer URL</li>
              <li>Hostname des zugreifenden Rechners</li>
              <li>Uhrzeit der Serveranfrage</li>
              <li>IP-Adresse</li>
            </ul>
            <Typography variant="body1">
              Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
              nicht vorgenommen. Grundlage für die Datenverarbeitung ist Art. 6
              Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung
              eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
            </Typography>
            <Typography variant="h5">Dienste von Drittanbietern</Typography>
            <Typography variant="body1">
              Mit dem Besuch unserer Website nutzen Sie Dienste von
              Drittanbietern. Wenn Sie damit nicht einverstanden sind, können
              Sie dies durch eine entsprechende Konfiguration Ihres
              Internetbrowsers verhindern. In einigen Fällen ist auch ein
              Wiederspruch (opt-out) für bestimmte Dienste möglich.
            </Typography>
            <Typography variant="h6">Google Analytics</Typography>
            <Typography variant="body1">
              Diese Website nutzt den Webanalysedienst Google Analytics. Mit der
              Nutzung von Google Analytics optimieren wir die Gestaltung und
              Auffindbarkeit unseres Webangebotes. Der Anbieter von Google
              Analytics ist Google Inc., 1600 Amphitheatre Parkway, Mountain
              View, CA 94043, USA. Google Analytics erfasst Daten über Ihre
              Benutzung dieser Website in Form von Cookies. Diese Cookies werden
              in der Regel an einen Server von Google in die USA übertragen und
              dort gespeichert. Wir haben auf dieser Website die Funktion
              IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von
              Google innerhalb von Mitgliedstaaten der Europäischen Union oder
              in anderen Vertragsstaaten des Abkommens über den Europäischen
              Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in
              Ausnahmefällen wird die volle IP-Adresse an einen Server von
              Google in den USA übertragen und dort gekürzt. Im Auftrag des
              Betreibers dieser Website wird Google diese Informationen
              benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über
              die Websiteaktivitäten zusammenzustellen und um weitere mit der
              Websitenutzung und der Internetnutzung verbundene Dienstleistungen
              gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von
              Google Analytics von Ihrem Browser übermittelte IP-Adresse wird
              nicht mit anderen Daten von Google zusammengeführt.
            </Typography>
            <Typography variant="body1">
              Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung
              abgeschlossen und setzen die strengen Vorgaben der deutschen
              Datenschutzbehörden bei der Nutzung von Google Analytics
              vollständig um.
            </Typography>
            <Typography variant="h6">
              Deaktivierung von Google Analytics
            </Typography>
            <Typography variant="body1">
              Sie können die Speicherung der Cookies durch eine entsprechende
              Einstellung Ihrer Browser-Software verhindern; wir weisen Sie
              jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
              sämtliche Funktionen dieser Website vollumfänglich werden nutzen
              können. Sie können darüber hinaus die Erfassung der durch den
              Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten
              (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser
              Daten durch Google verhindern, indem Sie das unter dem folgenden
              Link verfügbare Browser-Plugin herunterladen und installieren:{" "}
              <a href="https://tools.google.com/dlpage/gaoptout?hl=de.">
                https://tools.google.com/dlpage/gaoptout?hl=de
              </a>
            </Typography>
            <Typography variant="body1">
              Sie können die Erfassung Ihrer Daten durch Google Analytics
              verhindern, indem Sie auf folgenden Link klicken. Es wird ein
              Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei
              zukünftigen Besuchen dieser Website verhindert:{" "}
              <span className="link" onClick={disableGA}>
                Google Analytics deaktivieren
              </span>
              . Mehr Informationen zum Umgang mit Nutzerdaten bei Google
              Analytics finden Sie in der Datenschutzerklärung von Google:{" "}
              <a href="https://support.google.com/analytics/answer/6004245?hl=de">
                https://support.google.com/analytics/answer/6004245?hl=de
              </a>
            </Typography>
            <Typography variant="h5">
              Erfassung personenbezogener Daten
            </Typography>
            <Typography variant="body1">
              Ein Upload der Baumkatasterdaten ist grundsätzlich anonymisiert
              möglich. Personenbezogene Daten werden nur erhoben, wenn Sie uns
              diese beim Übermitteln der Baumkatastererdaten im Freitextfeld
              oder bei Kontakt über E-Mail freiwillig mitteilen. Wir erheben,
              verarbeiten und nutzen diese personenbezogenen Daten nur, soweit
              sie für die Begründung, inhaltliche Ausgestaltung oder Änderung
              des Rechtsverhältnisses erforderlich sind (Bestandsdaten).
            </Typography>
            <Typography variant="h5">Widerspruchsrecht</Typography>
            <Typography variant="body1">
              Sie haben das Recht, jederzeit gegen die Verarbeitung Sie
              betreffender personenbezogener Daten, die wir aufgrund unseres
              berechtigten Interesses verarbeiten (Art. 6 Abs. 1 lit. e oder f
              DSGVO), Widerspruch mit Wirkung für die Zukunft einzulegen.
              Weiteres regelt Art. 21 DSGVO. Änderung dieser
              Datenschutzerklärung
            </Typography>
            <Typography variant="body1">
              Diese Datenschutzerklärung ist aktuell gültig und hat den Stand
              20. Juli 2019.
            </Typography>
            <Typography variant="body1">
              Durch die Weiterentwicklung unserer Website und Angebote darüber
              oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher
              Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu
              ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit
              auf der Website abgerufen und ausgedruckt werden.
            </Typography>
            <Typography variant="h4">Impressum</Typography>
            <Typography variant="body1">
              <b>Terra Concordia gGmbH</b>
            </Typography>
            <Typography variant="body1">Bouchéstr. 79b</Typography>
            <Typography variant="body1">12435 Berlin</Typography>
            <Typography variant="body1">
              Telefon: +49 (0) 30 - 54 82 11 01
            </Typography>
            <Typography variant="body1">
              Fax: +49 (0) 30 - 89 64 82 77
            </Typography>
            <Typography variant="body1">E-Mail: info@baumcloud.org</Typography>
            <br />
            <Typography variant="body1">Geschäftsführer</Typography>
            <Typography variant="body1">Kai Gildhorn</Typography>
            <br />
            <Typography variant="body1">Handelsregister</Typography>
            <Typography variant="body1">
              Amtsgericht Charlottenburg, Handelsregisternr.: HRB 136223B
            </Typography>
            <br />
            <Typography variant="body1">Sitz der Gesellschaft</Typography>
            <Typography variant="body1">Berlin</Typography>
            <br />
            <Typography variant="body1">
              Umsatzsteueridentifikationsnummer
            </Typography>
            <Typography variant="body1">DE287614480</Typography>
            <Typography variant="h6">Haftungshinweis</Typography>
            <Typography variant="body1">
              Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine
              Haftung für die Inhalte externer Links und Karten. Für den Inhalt
              der verlinkten Seiten sind ausschließlich deren Betreiber
              verantwortlich.
            </Typography>
            <Typography variant="body1">
              Die EU-Website zur Online-Streitbeilegung finden Sie hier:{" "}
              <a
                href="http://ec.europa.eu/consumers/odr"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://ec.europa.eu/consumers/odr
              </a>
            </Typography>
          </div>
        </Paper>
      </Container>
    );
  }
}

export default withStyles(styles)(LegalView);
