/**
 * Created by Nicolas Looschen - info@pikobytes.de on 05.07.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { Grid, withStyles } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import axios from "axios";
import {
  getCredentials,
  setSessionId,
  getSessionId
} from "../util/authorization";
import { API_ENDPOINT } from "../typedefs/typedefs";
import "./ConsentView.scss";

const styles = theme => ({
  paper: {
    padding: 20,
    fontSize: "1.1rem"
  },
  section: {
    marginBottom: 10
  },
  title: {
    marginBottom: 15
  },
  list: {
    width: "100%"
  },
  listAvatar: {
    backgroundColor: "transparent"
  }
});

const IS_DEVELOPMENT_MODE =
  process.env.REACT_APP_CONSENT_REQUIRED === "true" ? false : true;

class ConsentView extends Component {
  static propTypes = {
    giveConsent: PropTypes.func.isRequired
  };

  state = {
    fetched: false
  };

  componentDidMount() {
    const consentRequired = process.env.REACT_APP_CONSENT_REQUIRED === "true";

    if (getSessionId() !== undefined) {
      this.setState({ fetched: true });

      if (!consentRequired) {
        this.giveConsent();
      }

      return;
    }

    this.fetchSessionID(consentRequired ? undefined : this.giveConsent);
  }

  fetchSessionID(optionalCallback) {
    const { password, username } = getCredentials();

    axios({
      method: "POST",
      url: `${API_ENDPOINT}/api/upload`,
      data: { undefined },
      auth: {
        username: username,
        password: password
      }
    })
      .then(response => {
        if (response.status === 200) {
          setSessionId(response.data.session_id);
          this.setState({ fetched: true });

          if (optionalCallback !== undefined) {
            optionalCallback();
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  giveConsent = () => {
    const { giveConsent, history } = this.props;

    giveConsent();
    // if dev mode is enabled go back to last page
    if (IS_DEVELOPMENT_MODE) {
      history.goBack();
    } else {
      history.push("/uploadTool");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Container maxWidth="lg" className="bk-view-consent">
        <Paper className={classes.paper}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.title}>
                Zustimmung zum Upload
              </Typography>
              <br />
              <Typography variant="h4">
                Anforderungen an die hochzuladenden Datensätze:
              </Typography>
              <List className={classes.list}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar className={classes.listAvatar}>
                      <NavigateNextIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <Typography variant="body1" className={classes.section}>
                    Bei den hochzuladenden Datensätzen muss es sich zwingend um{" "}
                    <b>"Offene Daten"</b> handeln. BaumCloud haftet nicht für
                    fälschlicherweise hochgeladene proprietäre Daten.{" "}
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar className={classes.listAvatar}>
                      <NavigateNextIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <Typography variant="body1" className={classes.section}>
                    Als Upload-Format wird ausschließlich das <b>CSV</b>-Format{" "}
                    unterstützt.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar className={classes.listAvatar}>
                      <NavigateNextIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <Typography variant="body1" className={classes.section}>
                    Die Standorte der Bäume müssen als Geographische Koordinaten
                    in Dezimalform in zwei Spalten angegeben werden. Zum
                    Beispiel sind die Geographischen Koordinaten des
                    Brandenburger Tors in Berlin X = 52.516276 Y = 13.377702 und
                    diejenigen des Kölner Doms sind X=50.941292, Y=6.957122.
                    Häufig werden die verschiedenen Koordinatenformate oder X
                    (=Längengrad) und Y (=Breitengrad) miteinander verwechselt.
                    Für einen benutzerfreundlichen Vergleich verschiedener
                    Koordinatenformate empfehlen wir den Koordiantenumrechner:{" "}
                    <Link
                      target="_new"
                      href="https://www.koordinaten-umrechner.de"
                      component="a"
                    >
                      www.koordinaten-umrechner.de
                    </Link>
                  </Typography>
                </ListItem>
              </List>
              <Typography variant="body1" className={classes.section}>
                Wenn Sie Fragen oder Probleme beim Hochladen Ihrer Daten haben,
                schreiben Sie eine E-Mail an{" "}
                <Link component="a" href="mailto:support@baumcloud.org">
                  support@baumcloud.org
                </Link>
                . Gerne helfen wir Ihnen weiter oder übernehmen für Sie die
                Umwandlung des Daten- oder Koordinatenformats.
              </Typography>
              <br />
              <Typography variant="h4" className={classes.title}>
                Einverständniserklärung:
              </Typography>
              <Typography variant="body1">
                Hiermit bestätige ich, dass die in den folgenden Schritten
                hochzuladenden Daten als <b>Offene Daten</b> lizenziert wurden.
                Im Fall einer Zuwiderhandlung komme ich für sämtlichen eventuell
                auftretenden Schaden auf. Die Terra Concordia gGmbH als Besitzer
                der Marke BaumCloud ist von sämtlicher Haftung ausgeschlossen.
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.giveConsent}
                disabled={!this.state.fetched}
              >
                Bestätigen
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }
}

export default withStyles(styles)(ConsentView);
