import React from "react";
import { withStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { Link } from "react-router-dom";

const styles = theme => ({
  paper: {
    padding: 20,
    fontSize: "1rem"
  }
});

const LoginNoticeView = props => (
  <Container maxWidth="sm">
    <Paper className={classNames(props.classes.paper, "bk-view-container")}>
      <Typography variant="h4">Login notwendig</Typography>
      <p>
        Bevor Sie das Uploadtool verwenden, müssen Sie sich einloggen. Wir
        senden Ihnen Ihre Login-Daten zu, fordern Sie diese bitte unter{" "}
        <a href="mailto:support@baumcloud.org" rel="nofollow">
          support@baumcloud.org
        </a>{" "}
        an. Loggen Sie sich dann hier <Link to="/login"> hier </Link> ein.
      </p>
    </Paper>
  </Container>
);

export default withStyles(styles)(LoginNoticeView);
