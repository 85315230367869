/**
 * Created by Nicolas Looschen - info@pikobytes.de on 05.07.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import { withTheme } from "../layouts/withTheme";
import { withStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { API_ENDPOINT } from "../typedefs/typedefs";
import { getCredentials, getSessionId } from "../util/authorization";
import Grid from "@material-ui/core/Grid";
import MessageHeaderIcon from "@material-ui/icons/ChatBubble";
import NavigationButtons from "../components/NavigationButtons";

const styles = theme => ({
  button: {
    marginTop: 20
  },
  navigation: {
    marginTop: 20
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    margin: theme.spacing(0, 0, 5, 0)
  },
  paperContainer: {
    height: "95%",
    width: "100%",
    padding: theme.spacing(0, 3)
  },
  title: {
    marginBottom: 15
  }
});
class MessageView extends Component {
  state = {
    message: this.props.message
  };

  componentDidMount() {
    this.props.finishStep();
  }

  onChange = event => this.setState({ message: event.target.value });

  submitMessage = event => {
    const { updateSetting } = this.props;
    const { message } = this.state;
    axios({
      method: "PUT",
      url: `${API_ENDPOINT}/api/upload/${getSessionId()}/textmessage`,
      data: message,
      headers: {
        "content-type": "text/plain"
      },
      auth: getCredentials()
    })
      .then(() => {
        updateSetting({ message }, true);
        this.props.finishStep(event !== undefined);
      })
      .catch();
  };

  render() {
    const { classes, history, location, finished } = this.props;
    const { message } = this.state;
    return (
      <React.Fragment>
        <div className={classes.paperContainer} ref={this.paperRef}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" className={classes.title}>
                  <MessageHeaderIcon />
                  Anmerkungen
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="message"
                  placeholder="Hinterlassen Sie uns eine Mitteilung!"
                  helperText="optional"
                  fullWidth
                  multiline
                  variant="outlined"
                  rows={8}
                  onChange={this.onChange}
                  value={message}
                />
              </Grid>
            </Grid>
          </Paper>
        </div>
        {
          <NavigationButtons
            history={history}
            location={location}
            finished={finished}
            customHandler={() => {
              const { updateSetting } = this.props;
              const { message } = this.state;
              updateSetting({ message }, true);
              if (message !== "") {
                this.submitMessage();
              }
            }}
          />
        }
      </React.Fragment>
    );
  }
}

export default withTheme(withStyles(styles)(MessageView));
