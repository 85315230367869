/**
 * Created by jacob.mendt@pikobytes.de on 27.06.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper/index";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import Link from "@material-ui/core/Link";

import "./FaqView.scss";

const ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const styles = () => ({
  paper: {
    padding: 20,
    fontSize: "1rem"
  }
});

/**
 * Page containing the privacy / legal information.
 */
class LegalView extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired
  };

  /**
   * Renders the components
   */
  render() {
    const { classes } = this.props;

    return (
      <Container maxWidth="lg">
        <Paper className={classNames(classes.paper, "bk-view-faq")}>
          <div>
            <Typography variant="h3">Fragen und Antworten</Typography>
            <br />
            <Typography variant="h5">Warum gibt es die BaumCloud?</Typography>
            <Typography variant="body1">
              In den vergangenen Jahren haben Kommunen vermehrt Daten über
              Stadtbäume veröffentlicht. Diese Daten liegen an unterschiedlichen
              Orten und Formaten zum Download bereit. BaumCloud ist der erste
              Dienst in Deutschland, der einen zentralen Up- und Download von
              Stadtbaumdaten in einem einheitlichen Format anbietet.
            </Typography>
            <br />
            <Typography variant="h5">
              Was haben wir davon, unsere Daten bei BaumCloud hochzuladen?
            </Typography>
            <Typography variant="body1">
              Ihre Daten werden automatisch in das BaumCloud-Standardformat
              umgewandelt und liegen zum Download bereit. BaumCloud übernimmt
              die Kosten für die Datenaufbereitung, das Hosting der Daten und
              den technischen Support.
            </Typography>
            <br />
            <Typography variant="h5">Was kostet der Service?</Typography>
            <Typography variant="body1">
              Der Service zum Upload und Download der Baumdaten ist kostenlos.
            </Typography>
            <br />
            <Typography variant="h5">
              Wo kann man die Daten über Stadtbäume runterladen?
            </Typography>
            <Typography variant="body1">
              Über den{" "}
              <a
                href={`${ENDPOINT}/mapserver?SERVICE=WFS&REQUEST=GetCapabilities&VERSION=2.0.0`}
                target="_blank"
                rel="noreferrer"
              >
                Web Feature Service
              </a>{" "}können Sie die Daten als WFS-File und über den {" "}<a
              href={process.env.REACT_APP_LINK_DATAVIEWER}
              target="_blank"
              rel="noreferrer"
            >
              BaumCloudViewer
            </a>{" "} als JSON-File herunterladen.
            </Typography>
            <br />
            <Typography variant="h5">
              Welche Informationen sind in den Stadtbaumdaten enthalten?
            </Typography>
            <Typography variant="body1">
              Das hängt von den jeweiligen Städten ab. Der Standort ist immer
              enthalten, meistens auch der botanische Name der Gattung und die
              deutsche Übersetzung, das Pflanzjahr, die Baumhöhe und der
              Kronendurchmesser. Auf Anfrage können weitere detailliertere
              Angaben über Baumart und Sorte sowie der Stammumfang bezogen
              werden.
            </Typography>
            <br />
            <Typography variant="h5">
              Welche Städte haben bereits ihre Stadtbaumdaten freigegeben?
            </Typography>
            <Typography variant="body1">
              Bislang wurden in Deutschland über 1,5 Millionen Baumdaten
              freigegeben. Dazu gehören u.a. Stadtbäume von Berlin, Hamburg,
              Köln, Frankfurt, Bonn, Leipzig, Chemnitz, Gelsenkirchen, Rostock,
              Krefeld, Troisdorf und Wesel.
            </Typography>
            <br />
            <Typography variant="h5">
              Welche Voraussetzung müssen die hochzuladenden Stadtbaumdaten
              erfüllen?
            </Typography>
            <Typography variant="body1">
              Die Stadtbaumdaten müssen unbedingt bereits als offene Daten
              vorliegen.
            </Typography>
            <br />
            <Typography variant="h5">
              Was sind offene Daten (Open Data)?
            </Typography>
            <Typography variant="body1">
              Wikipedia sagt: "Als offene Daten (Open Data) werden Daten
              bezeichnet, die von jedermann zu jedem Zweck genutzt,
              weiterverarbeitet und weiterverwendet dürfen."{" "}
              <Link
                href="https://de.wikipedia.org/wiki/Open_Data"
                target="_blank"
              >
                Hier
              </Link>{" "}
              gehts zum Wikipedia-Artikel.
            </Typography>
            <br />
            <Typography variant="h5">
              Welches Datenformat müssen die hochzuladenden Daten haben?
            </Typography>
            <Typography variant="body1">
              Die Daten müssen im CSV-Format vorliegen, bevor sie in die
              BaumCloud hochgeladen werden können. Wenn Sie Hilfe beim Umwandeln
              Ihrer Daten brauchen, kontaktieren Sie uns einfach unter{" "}
              <a href="mailto: support@baumcloud.org"> support@baumcloud.org</a>
            </Typography>
            <br />
            <Typography variant="h5">
              Welches Koordinatenformat müssen die hochzuladenden Daten haben?
            </Typography>
            <Typography variant="body1">
              Die Koordinaten sollen als Geographische Koordinaten in
              Dezimalgrad in zwei Spalten angegeben sein. Wenn Sie Hilfe beim
              Umwandeln Ihrer Koordinaten brauchen, kontaktieren Sie uns einfach
              unter{" "}
              <a href="mailto: support@baumcloud.org"> support@baumcloud.org</a>
            </Typography>
            <br />
            <Typography variant="h5">Was passiert mit den Daten?</Typography>
            <Typography variant="body1">
              Ihre Daten werden in das BaumCloud Standard-Datenschema
              umgewandelt und importiert. Anschließend werden die Daten hier
              kostenfrei zum Download angeboten:{" "}
              <Link
                href={`${ENDPOINT}/mapserver/?SERVICE=WFS&REQUEST=GetCapabilities&VERSION=2.0.0`}
                target="_blank"
              >
                Zum Download
              </Link>
            </Typography>
            <br />
            <Typography variant="h5">
              Unter welcher Offene-Daten-Lizenz werden die hochgeladenen Daten
              zum Download angeboten?
            </Typography>
            <Typography variant="body1">
              Die Daten liegen in unterschiedlichen Lizenzen vor. Die Sachdaten
              geben Ausschluss darüber, welche Daten unter welcher Lizenz
              angeboten werden (Lizenz-Feldname).
            </Typography>
            <br />
            <Typography variant="h5">
              Liegen meine Daten direkt nach dem Upload zum Download bereit?
            </Typography>
            <Typography variant="body1">
              Es dauert ca. 1-3 Wochen, bis die hochgeladenen Daten zum Download
              bereitgestellt werden.
            </Typography>
            <br />
            <Typography variant="h5">
              Können bereits hochgeladene Daten wieder gelöscht werden?
            </Typography>
            <Typography variant="body1">
              Ja, können sie. Schreiben Sie uns einfach eine E-Mail an{" "}
              <a href="mailto: support@baumcloud.org"> support@baumcloud.org</a>
            </Typography>
            <br />
            <Typography variant="h5">
              Wer hat die BaumCloud konzipiert?
            </Typography>
            <Typography variant="body1">
              Kai Gildhorn (Gründer der Plattform mundraub.org) und Martin
              Enzner (GIS-Experte) für die TerraConcordia gGmbH.
            </Typography>
            <br />
            <Typography variant="h5">Wer hat BaumCloud finanziert?</Typography>
            <Typography variant="body1">
              BaumCloud wurde als ein Teil des meinGruen-Projektes vom
              Bundesministerium für Verkehr und Digitale Infrastruktur (BMVI)
              finanziert.
            </Typography>
            <br />
            <Typography variant="h5">
              Wer hat die technische Entwicklung von BaumCloud umgesetzt?
            </Typography>
            <Typography variant="body1">
              Die Firma{" "}
              <Link href="https://pikobytes.de" target="_blank">
                PIKOBYTES
              </Link>{" "}
              aus Dresden.
            </Typography>
            <br />
          </div>
        </Paper>
      </Container>
    );
  }
}

export default withStyles(styles)(LegalView);
