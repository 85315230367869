/**
 * Created by jacob.mendt@pikobytes.de on 24.04.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import { dsvFormat } from "d3-dsv";
import axios from "axios";
import moment from "moment";

import { withTheme } from "../withTheme";
import "./UploadToolLayout.scss";

import { getCredentials, getSessionId } from "../../util/authorization";
import {
  API_ENDPOINT,
  FILE_DELIMITER_REVERSE_LOOKUP,
  FILE_ENCODINGS,
  FILE_ENCODINGS_REVERSE_LOOKUP,
  ERROR_CODE_TO_ERROR_MESSAGE,
  LICENSES_REVERSE_LOOKUP,
  ROUTES,
  ROUTES_REVERSE_LOOKUP,
  LICENSES,
  DEV_DUMMYDATA,
  STEPS
} from "../../typedefs/typedefs";
import Notification from "../../moduleAuth/components/Notification/Notification";
import MappingContainer from "../MappingContainer/MappingContainer";
import UploadView from "../../views/UploadView/UploadView";
import MessageView from "../../views/MessageView";
import CompletedView from "../../views/CompletedView";

moment.locale("de");

const styles = theme => ({
  button: {
    marginRight: theme.spacing()
  },
  helperText: {
    textAlign: "center"
  },
  navigationContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    padding: theme.spacing(0, 3)
  },
  notification: {
    paddingRight: 24
  },
  notificationContainer: {
    position: "absolute",
    top: 480
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  stepper: {
    padding: 0,
    margin: theme.spacing(2, 0, 1, 0),
    background: "none"
  },
  stepperButtons: {
    padding: 0,
    marginBottom: theme.spacing()
  }
});

const connector = (
  <StepConnector
    classes={{
      active: "connector-active",
      completed: "connector-completed",
      disabled: "connector-off",
      line: "connector-line"
    }}
  />
);

const IS_DEVELOPMENT_MODE =
  process.env.REACT_APP_CONSENT_REQUIRED === "true" ? false : true;

class UploadToolLayout extends Component {
  state = {
    actuality: moment(),
    error: "",
    file: undefined,
    fileEncoding: FILE_ENCODINGS["UTF-8"],
    fileDelimiter: "",
    fileUploaded: false,
    finishedSteps: STEPS.map(() => (IS_DEVELOPMENT_MODE ? true : false)),
    isParsing: false,
    license: LICENSES["CC0 1.0 Universal"],
    licenseName: "",
    licenseType: "",
    message: "",
    parsedContent: [],
    uniqueHeaders: true
  };

  componentDidMount() {
    const { consentGiven, history, user } = this.props;

    if (user.password === undefined || user.username === undefined) {
      history.push("/notice");
      return;
    }

    if (!consentGiven) {
      history.push("/consent");
    }

    this.checkRoute();
  }

  componentDidUpdate(prevProps, prevState) {
    const { consentGiven, history, user } = this.props;

    if (user.password === undefined || user.username === undefined) {
      history.push("/notice");
      return;
    }

    if (!consentGiven) {
      history.push("/consent");
      return;
    }

    this.checkRoute();
  }

  checkRoute() {
    const { finishedSteps } = this.state;
    const { location, history } = this.props;
    if (finishedSteps[ROUTES_REVERSE_LOOKUP[location.pathname] - 1] === false) {
      history.replace(ROUTES[finishedSteps.lastIndexOf(true) + 1]);
    }
  }

  /**
   * reset state, for example after file change
   */
  clearData = () => {
    this.resetStep();
    this.setState({
      file: undefined,
      fileDelimiter: "",
      fileUploaded: false,
      finishedSteps: STEPS.map(() => false),
      parsedContent: []
    });
  };

  /**
   * specifies activeStep as "finished"
   *
   * @param {boolean} shouldSwitch
   */
  finishStep = shouldSwitch => {
    const { location } = this.props;
    const newFinishedSteps = this.state.finishedSteps.slice(0);
    newFinishedSteps[ROUTES_REVERSE_LOOKUP[location.pathname]] = true;

    this.setState(
      { finishedSteps: newFinishedSteps },
      () => shouldSwitch && this.onNextButton()
    );
  };

  /**
   * generate external mapping from internal mapping
   * */
  generateUploadMapping() {
    const {
      actuality,
      fileDelimiter,
      fileEncoding,
      license,
      licenseName,
      licenseType
    } = this.state;
    return {
      license:
        license === LICENSES.Andere
          ? licenseType
          : LICENSES_REVERSE_LOOKUP[license],
      credits: licenseName,
      reference_date: actuality.format("YYYY-MM-DD"),
      csv_mapping: {
        charset: FILE_ENCODINGS_REVERSE_LOOKUP[fileEncoding],
        field_separator: FILE_DELIMITER_REVERSE_LOOKUP[fileDelimiter]
      }
    };
  }

  /**
   * resets error message
   */
  hideError = () => {
    this.setState({ error: "" });
  };

  /**
   * specifies activeStep as "unfinished"
   */
  resetStep = () => {
    const newFinishedSteps = this.state.finishedSteps.slice(0);
    newFinishedSteps[
      ROUTES_REVERSE_LOOKUP[this.props.location.pathname]
    ] = false;
    this.setState({ finishedSteps: newFinishedSteps });
  };

  /**
   * displays error message
   * @param {object} error - thrown by axios
   */
  showError = error => {
    if (axios.isCancel(error)) {
      console.log("request canceled");
      return;
    }

    this.setState({
      error: error.response
        ? ERROR_CODE_TO_ERROR_MESSAGE[error.response.status] === undefined
          ? "Es ist ein Fehler aufgetreten."
          : ERROR_CODE_TO_ERROR_MESSAGE[error.response.status]
        : "Es ist nicht möglich eine Verbindung zum Server aufzubauen."
    });
    if (error.response) {
      console.log(error.response.data.message);
    }
  };

  /**
   * upload csv file to backend
   */
  uploadCsvFile = (callback, cancelToken) => {
    const { parsedContent, fileDelimiter } = this.state;

    return axios({
      cancelToken: cancelToken,
      method: "PUT",
      url: `${API_ENDPOINT}/api/upload/${getSessionId()}/csv`,
      data: dsvFormat(FILE_DELIMITER_REVERSE_LOOKUP[fileDelimiter]).formatRows(
        parsedContent
      ),
      headers: { "content-type": "text/csv" },
      auth: getCredentials()
    })
      .then(callback)
      .then(() => {
        this.hideError();
        this.finishStep();
      })
      .catch(this.showError);
  };

  /**
   * update data, based on file, delimiter and encoding - tries to automatically detect the fileDelimiter if file
   * and encoding are specified
   * adds a new mapping file
   */
  updateData = () => {
    const { file, fileDelimiter, fileEncoding } = this.state;
    if (fileEncoding !== "" && file !== undefined) {
      const reader = new FileReader();
      this.setState({ isParsing: true }, () =>
        window.setTimeout(
          () =>
            reader.readAsText(
              file,
              fileEncoding === FILE_ENCODINGS.win1252
                ? "CP1252"
                : FILE_ENCODINGS_REVERSE_LOOKUP[fileEncoding]
            ),
          600
        )
      );

      reader.onload = () => {
        let uniqueHeaders = true;
        // determine fileDelimiter if not present
        let newFileDelimiter;
        if (fileDelimiter === "") {
          const firstN = reader.result.substring(0, 2000);
          const commaCount = (firstN.match(/,/g) || []).length;
          const semicolonCount = (firstN.match(/;/g) || []).length;
          newFileDelimiter = commaCount > semicolonCount ? 0 : 1;
        }

        const content = dsvFormat(
          FILE_DELIMITER_REVERSE_LOOKUP[
            newFileDelimiter === undefined ? fileDelimiter : newFileDelimiter
          ]
        ).parseRows(reader.result);

        let uniqueHead = new Set(content[0]);
        let doubleHeaders;
        if (!(uniqueHead.size === content[0].length)) {
          console.log(uniqueHead);
          doubleHeaders = content[0].slice(0);
          uniqueHead.forEach(head => {
            doubleHeaders.splice(doubleHeaders.indexOf(head), 1);
          });

          uniqueHeaders = false;
        }

        this.setState({
          uniqueHeaders,
          doubleHeaders,
          isParsing: false,
          parsedContent: uniqueHeaders ? content : [],
          ...(newFileDelimiter !== undefined && {
            fileDelimiter: newFileDelimiter
          })
        });
      };
    }
  };

  /**
   * update settings and update the parsed data if necessary
   * @param newSettings - new settings
   * @param doNotUpdate - flag that decides whether the parsed data should be updated
   */
  updateSetting = (newSettings, doNotUpdate) => {
    this.setState(newSettings, () => {
      if (!doNotUpdate) this.updateData();
    });
  };

  renderUploadView = props => {
    const {
      actuality,
      doubleHeaders,
      file,
      fileDelimiter,
      fileEncoding,
      fileUploaded,
      parsedContent,
      finishedSteps,
      isParsing,
      license,
      licenseName,
      licenseType,
      uniqueHeaders
    } = this.state;
    const { location } = this.props;

    const activeStep = parseInt(ROUTES_REVERSE_LOOKUP[location.pathname]);

    return getSessionId() !== undefined ? (
      <UploadView
        actuality={actuality}
        clearData={this.clearData}
        content={parsedContent}
        doubleHeaders={doubleHeaders}
        handleUpload={this.uploadCsvFile}
        file={file}
        fileDelimiter={fileDelimiter}
        fileEncoding={fileEncoding}
        fileUploaded={fileUploaded}
        finished={finishedSteps[activeStep]}
        isParsing={isParsing}
        license={license}
        licenseName={licenseName}
        licenseType={licenseType}
        updateSetting={this.updateSetting}
        uniqueHeaders={uniqueHeaders}
        {...props}
      />
    ) : (
      <Redirect to="/notice" />
    );
  };

  render() {
    const { classes, location } = this.props;
    const { finishedSteps, error, message, parsedContent } = this.state;
    const activeStep = parseInt(ROUTES_REVERSE_LOOKUP[location.pathname]);

    return (
      <React.Fragment>
        <Route
          exact
          path="/uploadTool"
          render={() => <Redirect to="/uploadTool/upload" />}
        />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={3}
          className={classes.container}
        >
          <Grid item xs={12}>
            <Container maxWidth="lg">
              <Grid item xs={12}>
                <Stepper
                  className={classNames(classes.stepper, "bk-upload-stepper")}
                  activeStep={activeStep}
                  orientation="horizontal"
                  connector={connector}
                >
                  {STEPS.map(label => (
                    <Step key={label}>
                      <StepLabel className="bk-stepper-label">
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            </Container>
            {activeStep === STEPS.length && (
              <Paper square elevation={0} className={classes.resetContainer}>
                <Typography>
                  Alle Schritte abgeschlossen! Möchten Sie einen weiteren
                  Hochladevorgang starten oder noch einmal zurück?
                </Typography>
                <Button onClick={this.onBackButton} className={classes.button}>
                  Back
                </Button>
                <Button onClick={this.onResetButton} className={classes.button}>
                  Reset
                </Button>
              </Paper>
            )}
          </Grid>
          <Grid item xs={12} className={classes.container}>
            <Route path="/uploadTool/completed" component={CompletedView} />
            <Route path="/uploadTool/upload" render={this.renderUploadView} />
            <Route
              path="/uploadTool/message"
              render={props => (
                <MessageView
                  {...props}
                  finished={finishedSteps[activeStep]}
                  finishStep={this.finishStep}
                  message={message}
                  updateSetting={this.updateSetting}
                />
              )}
            />
            <MappingContainer
              content={
                IS_DEVELOPMENT_MODE && parsedContent.length === 0
                  ? DEV_DUMMYDATA
                  : parsedContent
              }
              fallback={() => (
                <Redirect to={ROUTES[finishedSteps.lastIndexOf(true) + 1]} />
              )}
              finished={finishedSteps[activeStep]}
              finishStep={this.finishStep}
              hideError={this.hideError}
              message={message}
              resetStep={this.resetStep}
              showError={this.showError}
              uploadMapping={this.generateUploadMapping()}
            />
          </Grid>
        </Grid>

        {error !== "" && (
          <Grid container className={classes.notificationContainer}>
            <Grid item xs={2} className={classes.notification}>
              <Notification
                key={error}
                variant="error"
                message={error}
                onClose={this.hideError}
              />
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    );
  }
}

UploadToolLayout.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTheme(withStyles(styles)(UploadToolLayout));
