/**
 * Created by jacob.mendt@pikobytes.de on 25.04.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  getCredentials,
  resetCredentials,
  setCredentials
} from "../../../util/authorization";
import Notification from "../../components/Notification/Notification";
import { API_ENDPOINT } from "../../../typedefs/typedefs";
import { Link } from "react-router-dom";
// Load global options
const SH_DATA_API = API_ENDPOINT;

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing()
  },
  submit: {
    marginTop: theme.spacing(3)
  },
  contentInfo: {
    width: "100%",
    marginTop: theme.spacing(5)
  },
  notificaton: {
    margin: theme.spacing(),
    width: "100%"
  },
  logoutButton: {
    alignSelf: "flex-end",
    marginTop: theme.spacing(2)
  }
});

/**
 * Checks the user credentials.
 * @param username
 * @param password
 * @returns {{ valid: boolean, error: string|undefined }}
 */
async function validCredentials(username, password) {
  // check if the user credentials are basically valid
  if (
    username === undefined ||
    password === undefined ||
    username.length === 0 ||
    password.length === 0
  ) {
    return {
      valid: false,
      error: "Die eingegebenen Benutzerdaten sind ungültig."
    };
  }

  // check if the given user credentials are valid against the backend service
  return axios({
    method: "GET",
    url: `${SH_DATA_API}/api/info`,
    data: { undefined },
    auth: {
      username: username,
      password: password
    }
  })
    .then(response => {
      if (response.status === 200) {
        return {
          valid: true,
          error: undefined
        };
      }

      return {
        valid: false,
        error:
          "Bei dem Parsen der Antwort des Servers ist etwas schief gelaufen!"
      };
    })
    .catch(error => {
      return {
        valid: false,
        error: `${error.message}. Bitte überprüfen Sie ihre Benutzerdaten.`
      };
    });
}

export class LoginView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: undefined,
      password: props.user.password !== undefined ? props.user.password : "",
      username: props.user.username !== undefined ? props.user.username : ""
    };
  }

  componentDidMount() {
    const credentials = getCredentials();

    if (credentials !== undefined) {
      this.props.onUpdateCredentials(credentials);
      this.props.onSubmit();
    }
  }

  handleChange(fieldName, { target }) {
    this.setState({
      [fieldName]: target.value
    });
  }

  handleKeyPress = e => {
    const { password, username } = this.state;

    if (e.key === "Enter" && password !== undefined && username !== undefined) {
      this.handleSubmit();
    }
  };

  async handleSubmit() {
    const { password, username } = this.state;

    // validate user credentials against the backend
    const { valid, error } = await validCredentials(username, password);

    if (valid) {
      // update the state
      this.setState({
        error: undefined
      });

      // update the sessionStorage with the userCredentials
      setCredentials({ password, username });

      // update the redux state
      this.props.onUpdateCredentials({ password, username });

      // if passed trigger submit hook
      if (this.props.onSubmit) {
        this.props.onSubmit();
      }
    } else {
      this.setState({
        error: error !== undefined ? error : "Benutzerdaten nicht valide"
      });
    }
  }

  handleLogout() {
    // first reset the credentials
    resetCredentials();

    // update the redux state
    this.props.onUpdateCredentials({
      password: undefined,
      username: undefined
    });

    // second call trigger if registered
    if (this.props.onLogout) {
      this.props.onLogout();
    } else {
      // finally update the state
      this.setState({
        password: "",
        username: ""
      });
    }
  }

  /**
   * This part should be rendered if the user is already logged in yet.
   */
  renderInfo() {
    const { classes, user } = this.props;
    const { username } = user;
    return (
      <React.Fragment>
        <Avatar component="div" className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Profil: {username}
        </Typography>
        <div className={classes.contentInfo}>
          <Typography variant="body1">
            Viel Spaß beim{" "}
            <Link to="/uploadTool">Hochladen Ihrer Baumdaten</Link>.{" "}
          </Typography>
        </div>
        <Button
          className={classes.logoutButton}
          variant="contained"
          color="primary"
          onClick={this.handleLogout.bind(this)}
        >
          Ausloggen
        </Button>
      </React.Fragment>
    );
  }

  /**
   * This part should be rendered if the user is not logged in yet.
   */
  renderLogin() {
    const { classes } = this.props;
    const { error, password, username } = this.state;
    return (
      <React.Fragment>
        <Avatar component="div" className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Anmeldung
        </Typography>
        {error && (
          <Notification
            variant="error"
            className={classes.notificaton}
            message={error}
            onClose={() => this.setState({ error: undefined })}
          />
        )}
        <div className={classes.form}>
          <FormControl margin="normal" required fullWidth component="div">
            <InputLabel htmlFor="username">Benutzername</InputLabel>
            <Input
              id="username"
              name="username"
              autoComplete="current-username"
              autoFocus
              onChange={this.handleChange.bind(this, "username")}
              onKeyPress={this.handleKeyPress.bind(this)}
              value={username}
            />
          </FormControl>
          <FormControl margin="normal" required fullWidth component="div">
            <InputLabel htmlFor="password">Passwort</InputLabel>
            <Input
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={this.handleChange.bind(this, "password")}
              onKeyPress={this.handleKeyPress.bind(this)}
              value={password}
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={this.handleSubmit.bind(this)}
          >
            Einloggen
          </Button>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { classes, user } = this.props;
    const { username, password } = user;
    const isLoggedIn = username !== undefined && password !== undefined;

    return (
      <Container maxWidth="sm">
        <Paper className={classes.paper}>
          {isLoggedIn ? this.renderInfo() : this.renderLogin()}
        </Paper>
      </Container>
    );
  }
}

LoginView.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string
  }).isRequired,
  onLogout: PropTypes.func,
  onSubmit: PropTypes.func,
  onUpdateCredentials: PropTypes.func.isRequired
};

// connect the components with the global application state.
export default withStyles(styles)(LoginView);
