/**
 * Created by jacob.mendt@pikobytes.de on 15.03.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React from "react";
import ReactDOM from "react-dom";
import { Router, Route } from "react-router-dom";
import { createHashHistory } from "history";
import { detect } from "detect-browser";

/* Imports needed for the use of material-ui */
import "typeface-roboto";

import "./index.scss";
import App from "./layouts/AppLayout";
import * as serviceWorker from "./serviceWorker";

// Element Id of the app container. The content of this element is replaced with
// the application rendered content.
const APP_CONTAINER_ID = "root";

// fallback behavior in case the user uses firefox
const browserNameIE = detect().name === "ie";
if (browserNameIE) {
  const containerEl = document.getElementById(APP_CONTAINER_ID);
  const defaultMsg =
    'Haben Sie anschließend immer noch Probleme wenden Sie sich bitte an <a href="mailto:info@pikobytes.de" title="Contact Email">info@pikobytes.de</a>.';
  const customMsg = browserNameIE
    ? "<h2>Fehlende Browser Unterstüzung</h2><div>Die Anwendung unterstützt keinen Internet Explorer. Bitte wechseln Sie zu einem neueren Browser wie Chrome, Firefox, Edge, Opera oder Safari. "
    : "<h2>Fehlende WebGL Unterstüzung</h2><div>Ihr Browser unterstützt kein WebGL. Bitte wechseln Sie zu einer neueren Browser-Version von Chrome, Firefox, Edge, Opera oder Safari. ";
  const msg = document.createElement("h1");
  msg.innerHTML = `<div class="missing-browser-support">${customMsg}${defaultMsg}</div></div>`;
  containerEl.innerHTML = "";
  containerEl.appendChild(msg);
} else {
  // create a hashHistory for single application routing
  const hashHistory = createHashHistory();

  const Main = () => (
    <Router history={hashHistory}>
      <Route path="/" component={App} />
    </Router>
  );

  ReactDOM.render(<Main />, document.getElementById(APP_CONTAINER_ID));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
