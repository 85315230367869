/**
 * Created by Nicolas Looschen - info@pikobytes.de on 22.06.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router";
import {
  API_ENDPOINT,
  DATABASE_FIELDS_DISPLAY,
  DEV_DUMMYDATA
} from "../../typedefs/typedefs";
import MappingView from "../../views/MappingView/MappingView";
import {
  generateExternalMapping,
  testMapping,
  transformHeader
} from "../../util/util";
import axios from "axios";
import { getCredentials, getSessionId } from "../../util/authorization";

import SubmitView from "../../views/SubmitView";

const IS_DEVELOPMENT_MODE =
  process.env.REACT_APP_CONSENT_REQUIRED === "true" ? false : true;

export default class MappingContainer extends Component {
  static propTypes = {
    content: PropTypes.array.isRequired,
    fallback: PropTypes.func,
    finishStep: PropTypes.func.isRequired,
    hideError: PropTypes.func.isRequired,
    message: PropTypes.string,
    showError: PropTypes.func.isRequired,
    uploadMapping: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    const mapping = {};

    if (props.content.length !== 0) {
      props.content[0].forEach(
        entry => (mapping[entry] = { target: -1, quality: "", uom: "" })
      );
    }

    this.state = {
      data: IS_DEVELOPMENT_MODE ? DEV_DUMMYDATA : [],
      height: 0,
      loading: false,
      mapping,
      receivedMapping: [],
      previewType: false,
      submitted: false
    };
  }

  componentDidUpdate(prevProps) {
    const { content } = this.props;

    if (prevProps.content.length !== this.props.content.length) {
      const mapping = {};
      let receivedMapping;
      // ensure inital mapping is present
      if (this.props.content.length !== 0) {
        this.props.content[0].forEach(
          entry => (mapping[entry] = { target: -1, quality: "", uom: "" })
        );

        const header = Object.keys(DATABASE_FIELDS_DISPLAY)
          .filter(x => x >= 0)
          .map(key => DATABASE_FIELDS_DISPLAY[key]);

        const emptyContent = Array.apply(null, Array(header.length)).map(
          () => ""
        );

        const body = [];

        for (let i = 0; i < content.length - 1; i++) {
          body.push(emptyContent);
        }

        receivedMapping = [header, ...body];
      }

      this.setState({
        mapping,
        data: this.getPreviewData(),
        ...(receivedMapping && { receivedMapping: receivedMapping })
      });
    }
  }

  /**
   * returns preview data, based on the switch state full/partial and mapping/original
   * @returns {any}
   */
  getPreviewData() {
    const { content } = this.props;
    const { receivedMapping, previewType } = this.state;

    return previewType ? receivedMapping : content;
  }

  /**
   * toggle preview Type
   */
  handlePreviewTypeSwitch = newValue => {
    this.setState(
      {
        previewType:
          newValue.target === undefined ? newValue : newValue.target.checked
      },
      () => this.setState({ data: this.getPreviewData() })
    );
  };

  onResize = () =>
    this.setState({
      height: window.innerHeight
    }); // padding = 48 and ~10px for scrollbar

  /**
   * fetch test mapping from server
   * {Object} - internal mapping representation
   */
  requestTestMapping = (mapping, cancelToken) => {
    const { hideError, showError, uploadMapping } = this.props;

    this.setState({ loading: true });

    return new Promise(resolve => {
      testMapping(
        {
          cancelToken: cancelToken,
          uploadMapping: {
            ...uploadMapping,
            csv_mapping: {
              ...uploadMapping.csv_mapping,
              field_mappings: { ...generateExternalMapping(mapping) }
            }
          }
        },
        response => {
          hideError();
          this.setState(
            {
              loading: false,
              receivedMapping: transformHeader(response.data)
            },
            () => {
              resolve();
              this.setState({ data: this.getPreviewData() });
            }
          );
        },
        error => {
          this.setState({ loading: false }, () => showError(error));
        }
      );
    });
  };

  submit = () => this.setState({ submitted: true });

  renderMappingView = props => {
    const {
      data,
      height,
      loading,
      mapping,
      receivedMapping,
      previewType
    } = this.state;

    const { content, finishStep, finished, fallback, resetStep } = this.props;
    return content.length !== 0 ? (
      <MappingView
        {...props}
        content={content}
        data={data}
        handleSwitch={this.handlePreviewTypeSwitch}
        handleTestMapping={this.requestTestMapping}
        handleUpload={this.uploadMappingFile}
        height={height}
        finished={finished}
        finishStep={finishStep}
        loading={loading}
        mapping={mapping}
        onResize={this.onResize}
        previewType={previewType}
        receivedMapping={receivedMapping}
        resetStep={resetStep}
        updateMapping={this.updateMapping}
      />
    ) : (
      <React.Fragment>{fallback()}</React.Fragment>
    );
  };

  renderSubmitView = props => {
    const { finished, finishStep, message, uploadMapping } = this.props;
    const { mapping, height, receivedMapping, submitted } = this.state;

    return (
      <SubmitView
        {...props}
        data={receivedMapping}
        height={height}
        finished={finished}
        finishStep={finishStep}
        mapping={mapping}
        message={message}
        metadata={uploadMapping}
        submit={this.submit}
        submitted={submitted}
      />
    );
  };

  /**
   * upload mapping file to backend
   */
  uploadMappingFile = (mapping, event) => {
    const { showError, uploadMapping } = this.props;
    if (event !== undefined) {
      event.preventDefault();
    }
    //upload new mapping to endpoint
    return axios({
      method: "PUT",
      url: `${API_ENDPOINT}/api/upload/${getSessionId()}/metadata`,
      data: JSON.stringify({
        ...uploadMapping,
        csv_mapping: {
          ...uploadMapping.csv_mapping,
          field_mappings: { ...generateExternalMapping(mapping) }
        }
      }),
      headers: { "content-type": "application/json" },
      auth: getCredentials()
    }).catch(showError);
  };

  updateMapping = mapping => this.setState({ mapping });

  render() {
    return (
      <Switch>
        <Route path="/uploadTool/mapping" render={this.renderMappingView} />;
        <Route path="/uploadTool/submit" render={this.renderSubmitView} />
      </Switch>
    );
  }
}
