/**
 * Created by Nicolas Looschen - info@pikobytes.de on 20.07.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import { Grid, withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

const styles = theme => ({
  paper: {
    padding: 20,
    fontSize: "1.1rem"
  },
  section: {
    marginBottom: 10
  },
  title: {
    marginBottom: 15
  }
});

class CompletedView extends Component {
  componentDidMount() {
    // reload the page with a slight delay of 5 seconds

    window.setTimeout(
      () => (window.location.href = window.location.origin),
      2500
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Container maxWidth="lg">
        <Paper className={classes.paper}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.title}>
                Der Upload war Erfolgreich!
              </Typography>
              <Typography variant="body1" className={classes.section}>
                Sie werden nun auf die Startseite weitergeleitet.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }
}

export default withStyles(styles)(CompletedView);
