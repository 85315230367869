/**
 * Created by Nicolas Looschen - info@pikobytes.de on 24.05.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

import { generateReverseLookup } from "../util/util";

/**
 * Url of the api endpoint, supplied by .env
 * @type {string}
 */
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

// fieldnumbers in "DATABASE_FIELDS_DISPLAY" for which the quality has to be determined
const ASK_QUALITY = [7, 8, 9, 10, 11, 12, 13];

// fieldnumbers in "DATABASE_FIELDS_DISPLAY" for which the unit of measurement has to be determined
const ASK_UNIT = [7, 8, 9, 10, 13];

/**
 * Database fields in the internal mapping
 * @type {{FULL_GERMAN_NAME: number, TRUNK_CIRCUMFERENCE: number, LNG: number, TREE_AGE: number, FULL_BOTANIC_NAME: number, GENUS: number, TREETOP_DIAMETER: number, TREE_HEIGHT: number, SPECIES: number, TRUNK_DIAMETER: number, YEAR_OF_PLANTING: number, INFRASPEC: number, FULL_BOTANIC_AND_GERMAN_NAME: number, TREETOP_CIRCUMFERENCE: number, LAT: number}}
 */
const DATABASE_FIELDS = {
  LAT: 0,
  LNG: 1,
  FULL_BOTANIC_NAME: 2,
  GENUS: 3,
  SPECIES: 4,
  INFRASPEC: 5,
  FULL_GERMAN_NAME: 6,
  TRUNK_CIRCUMFERENCE: 7,
  TRUNK_DIAMETER: 8,
  TREETOP_CIRCUMFERENCE: 9,
  TREETOP_DIAMETER: 10,
  YEAR_OF_PLANTING: 11,
  TREE_AGE: 12,
  TREE_HEIGHT: 13
};

/**
 * mapping from the DATABASE_FIELDS enum values to actual strings with the field names
 * @type {{}}
 */
const DATABASE_FIELDS_REVERSE_LOOKUP = generateReverseLookup(DATABASE_FIELDS);

/**
 * german display names for the internal database fields
 * @type {{[p: string]: string, [p: number]: string, "11": string, "12": string, "13": string, "14": string, "0": string, "1": string, "2": string, "3": string, "4": string, "5": string, "6": string, "7": string, "8": string, "9": string, "10": string}}
 */
const DATABASE_FIELDS_DISPLAY = {
  [-1]: "",
  0: "Geographische Breite",
  1: "Geographische Länge",
  2: "Botanischer Name",
  3: "Gattung",
  4: "Art",
  5: "Sorte",
  6: "Deutscher Name",
  7: "Stammumfang",
  8: "Stammdurchmesser",
  9: "Kronenumfang",
  10: "Kronendurchmesser",
  11: "Pflanzjahr",
  12: "Alter",
  13: "Baumhöhe"
};

const DEV_DUMMYDATA = [
  [
    "X",
    "Y",
    "ID",
    "GATTUNGART",
    "GATTUNG",
    "GA_LANG",
    "ST_DURCHM",
    "ST_UMFANG",
    "BAUMHOEHE",
    "KR_DURCHM",
    "RECHTSWERT",
    "HOCHWERT"
  ],
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
];

/**
 * mapping from http error code to displayed error message
 * @type {{"400": string, "401": string, "413": string, "403": string, "404": string, "503": string}}
 */
const ERROR_CODE_TO_ERROR_MESSAGE = {
  400: "Der Server hat das übergebene Mapping abgelehnt. Es liegt ein Fehler mit den bereitgestellten Daten oder dem Mapping vor.",
  401: "Es liegt keine gültige Authentifizierung vor.",
  403: "Der Zugriff ist untersagt. Wahrscheinlich liegt keine gültige Authentifizierung vor.",
  404: "Seite nicht gefunden. Überprüfen sie die eingegebene Adresse.",
  413: "Die ausgewählte Datei ist zu groß und konnte daher nicht erfolgreich hochgeladen werden.",
  503: "Der Server ist zur Zeit nicht erreichbar."
};

/**
 * enum of possible file delimiters
 * @type {{Komma: number, Semikolon: number, Tabstop: number}}
 */
const FILE_DELIMITER = { Komma: 0, Semikolon: 1, Tabstop: 2 };

/**
 * mapping from the FILE_DELIMITER enum values to actual strings of the delimiter
 * @type {{"0": string, "1": string, "2": string}}
 */
const FILE_DELIMITER_REVERSE_LOOKUP = { 0: ",", 1: ";", 2: "\t" };

/**
 * enum of possible file encodings
 * @type {{"UTF-8": number, win1252: number}}
 */
const FILE_ENCODINGS = { "UTF-8": 0, win1252: 1 };

/**
 * mapping from the FILE_ENCODINGS enum values to actual strings of the delimiter
 * @type {{}}
 */
const FILE_ENCODINGS_REVERSE_LOOKUP = generateReverseLookup(FILE_ENCODINGS);

/**
 * enum of possible licenses
 * @type {{CC0: number, "Deutschland Namensnennung 2.0": number, "Deutschland Namensnennung 3.0": number, Andere: number}}
 */
const LICENSES = {
  "Deutschland Namensnennung 2.0": 0,
  "Deutschland Namensnennung 3.0": 1,
  "CC0 1.0 Universal": 2,
  "Deutschland Zero Version 2.0": 3,
  Andere: 4
};

/**
 * mapping from the LICENSE enum values to actual strings of license
 * @type {{}}
 */
const LICENSES_REVERSE_LOOKUP = generateReverseLookup(LICENSES);

/**
 * enum with all routes
 * @type {{"0": string, "1": string, "2": string, "3": string, "4": string}}
 */
const ROUTES = {
  0: "/uploadTool/upload",
  1: "/uploadTool/mapping",
  2: "/uploadTool/message",
  3: "/uploadTool/submit"
};

/**
 * maps ROUTES enum values to string values of the route
 * @type {{}}
 */
const ROUTES_REVERSE_LOOKUP = generateReverseLookup(ROUTES);

const STEPS = [
  "Datei Upload",
  "Mapping des Schemas",
  "Mitteilung hinterlassen",
  "Überprüfen der Eingaben"
];

/**
 * enum with possible values for quality
 * @type {{"0": string, "1": string, "2": string}}
 */
const QUALITY = { 0: "ESTIMATED", 1: "MEASURED", 2: "ESTIMATED_OR_MEASURED" };

/**
 * maps QUALITY enum values to german display strings
 * @type {{"0": string, "1": string, "2": string}}
 */
const QUALITY_DISPLAY = {
  0: "geschätzt",
  1: "gemessen",
  2: "geschätzt oder gemessen"
};

/**
 * enum with possible units of measurement
 * @type {{cm: number, m: number}}
 */
const UOM = { m: 0, cm: 1 };

/**
 * maps UOM enum values to string values with the unit of measurement
 * @type {{}}
 */
const UOM_REVERSE_LOOKUP = generateReverseLookup(UOM);

export {
  API_ENDPOINT,
  ASK_QUALITY,
  ASK_UNIT,
  DATABASE_FIELDS,
  DATABASE_FIELDS_REVERSE_LOOKUP,
  DATABASE_FIELDS_DISPLAY,
  DEV_DUMMYDATA,
  ERROR_CODE_TO_ERROR_MESSAGE,
  FILE_DELIMITER,
  FILE_DELIMITER_REVERSE_LOOKUP,
  FILE_ENCODINGS,
  FILE_ENCODINGS_REVERSE_LOOKUP,
  LICENSES,
  LICENSES_REVERSE_LOOKUP,
  ROUTES,
  ROUTES_REVERSE_LOOKUP,
  STEPS,
  QUALITY,
  QUALITY_DISPLAY,
  UOM,
  UOM_REVERSE_LOOKUP
};
