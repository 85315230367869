/**
 * Created by Nicolas Looschen - info@pikobytes.de on 24.05.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import axios from "axios";
import { getCredentials, getSessionId } from "./authorization";
import {
  API_ENDPOINT,
  DATABASE_FIELDS,
  DATABASE_FIELDS_DISPLAY,
  DATABASE_FIELDS_REVERSE_LOOKUP,
  QUALITY,
  UOM_REVERSE_LOOKUP
} from "../typedefs/typedefs";
import { dsvFormat } from "d3-dsv";

/**
 * generate external field mapping from internal mapping representation
 * @param mapping internal mapping representation
 * @returns {?}
 */
function generateExternalMapping(mapping) {
  const externalMapping = {};

  Object.entries(mapping).forEach(function(mappingEntry) {
    const [source, targetObject] = mappingEntry;

    const { quality, uom, target } = targetObject;

    if (target !== -1) {
      // only set uom and quality if they do exist in the mapping

      Object.assign(externalMapping, {
        [DATABASE_FIELDS_REVERSE_LOOKUP[target]]: {
          source: source,
          ...(uom !== "" && { uom: UOM_REVERSE_LOOKUP[uom] }),
          ...(quality !== "" && { quality: QUALITY[quality] })
        }
      });
    }
  });

  return externalMapping;
}

/**
 * generate reversed object from object (keys <-> values) for bijective mapping
 * @param object - any object
 */
function generateReverseLookup(object) {
  const reverseLookup = {};
  Object.entries(object).forEach(
    objectEntry => (reverseLookup[objectEntry[1]] = objectEntry[0])
  );
  return reverseLookup;
}

/**
 * render menu items, used in dropdown menus
 * @param type object which contains the key value pairs
 * @returns {*[]}
 */
const renderMenuItems = type =>
  Object.keys(type).map(key => (
    <MenuItem key={type[key]} value={type[key]}>
      {key}
    </MenuItem>
  ));

/**
 * render menu items, used in dropdown menus
 * key/values are inverted
 * @param type
 * @returns {*[]}
 */
function renderMenuItemsWithLookup(type) {
  return Object.keys(type).map(key => (
    <MenuItem key={type[key]} value={key}>
      {type[key]}
    </MenuItem>
  ));
}

/**
 * sums up all list elements till specified index
 * @param {[*]} list
 * @param {number} index
 * @returns {number|*}
 */
function sumTill(list, index) {
  if (list.length - 1 < index && index !== 0) {
    throw new Error("Index out of bound");
  }
  let a = 0;
  for (let i = 0; i < index; i++) {
    a = a + list[i];
  }
  return a;
}

/**
 * tests an uploadMapping against the backend service
 * @param uploadMapping
 * @param full  specifies whether full preview should be fetched
 * @param stage  specifies the mapping stage
 * @param callback  optional callback
 * @param errorCallback optional errorCallback - runs if request fails
 * @returns {Promise<AxiosResponse<any> | void>}
 */
function testMapping({ uploadMapping, cancelToken }, callback, errorCallback) {
  return axios({
    cancelToken: cancelToken,
    method: "POST",
    url: `${API_ENDPOINT}/api/upload/${getSessionId()}/test-mapping?limit=10000000`,
    data: JSON.stringify(uploadMapping),
    headers: {
      "content-type": "application/json"
    },
    auth: getCredentials()
  })
    .then(callback)
    .catch(errorCallback);
}

/**
 * transforms header of internal csv representation from english to german display values
 * @param data
 * @returns {Array}
 */
function transformHeader(data) {
  const result = dsvFormat(",").parseRows(data);
  result[0] = result[0].map(
    headerElement =>
      DATABASE_FIELDS_DISPLAY[DATABASE_FIELDS[headerElement.toUpperCase()]]
  );
  return result;
}

export {
  generateExternalMapping,
  generateReverseLookup,
  renderMenuItems,
  renderMenuItemsWithLookup,
  sumTill,
  testMapping,
  transformHeader
};

/*
function which might be useful for later usecase (check whether file
is present on server and fetch it to continue a previously started upload progress

function checkPresenceOnServer(endPoint) {
  return axios({
    method: "HEAD",
    url: `${API_ENDPOINT}/api/upload/${getSessionId()}/${endPoint}`,
    auth: getCredentials()
  });
}
*/
