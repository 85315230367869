/**
 * Created by Nicolas Looschen - info@pikobytes.de on 26.06.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React from "react";
import { Link } from "react-router-dom";
import ExternalLink from "@material-ui/core/Link";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CloudUploadIcon from "@material-ui/icons/CloudUploadOutlined";
import CloudDownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import Paper from "@material-ui/core/Paper/index";
import Typography from "@material-ui/core/Typography/index";
import "./WelcomeView.scss";
import LogoBMVI from "./bmvi-logo-download.jpg";
import LogoMeinGruen from "./meinGruen_nav.png";
import LogoMundRaub from "./mundraub-logo.svg";
import LogoMFund from "./mfund-logo-download.jpg";

const styles = theme => ({
  backgroundBanner: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  },
  paper: {
    fontSize: "1rem"
  },
  content: {
    padding: 20
  },
  section: {
    width: "100%",
    display: "flex",
    position: "relative",
    alignItems: "center"
  },
  sectionGoals: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  sectionWorks: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  sectionCredits: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  sectionElDivider: {
    width: 55,
    height: 2,
    margin: "8px auto 0",
    display: "block",
    backgroundColor: theme.palette.secondary.light
  },
  sectionElTitle: {
    marginBottom: 15
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(1, 5)
  },
  gridItemLogo: {
    width: "100%"
  },
  footer: {
    width: "100%",
    display: "flex",
    position: "relative",
    alignItems: "center",
    padding: theme.spacing(1.25, 0),
    marginBottom: -60
  },
  footerText: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  }
});

const WelcomeView = props => {
  const { classes } = props;
  return (
    <Container maxWidth="lg" className="bk-welcome-view">
      <Paper className={classes.paper}>
        <section
          className={classNames(
            classes.section,
            classes.backgroundBanner,
            "section-banner"
          )}
        >
          <div className={classes.bannerTitle}>
            <Typography variant="h3">
              Baum<span>Cloud</span> – Eine Datenbank für Stadtbäume.
            </Typography>
          </div>
        </section>
        <section className={classNames(classes.section, "section-goals")}>
          <div className={classes.sectionGoals}>
            <Typography variant="body1">
              Das Ziel von BaumCloud ist es, möglichst viele digitale Daten von
              Stadtbäumen zu sammeln, in ein einheitliches Format zu bringen und
              dieses der Öffentlichkeit zugänglich zu machen.
            </Typography>
            <Typography variant="body1">
              Viele Kommunen, Landkreise und Liegenschaftsverwaltungen pflegen
              digitale Baumkataster für die Verwaltung ihres Baumbestandes.
              Einige dieser Akteure haben ihre Daten als{" "}
              <ExternalLink
                target="_blank"
                href="https://de.wikipedia.org/wiki/Open_Data"
              >
                offene Daten
              </ExternalLink>{" "}
              der Öffentlichkeit freigegeben. Allerdings sind diese Daten an
              verschiedenen Orten verfügbar und unterschiedlich strukturiert.
            </Typography>
            <Typography variant="body1">
              BaumCloud ermöglicht eine zentrale Sammlung und Vereinheitlichung
              der digitalen Daten von Bäumen. Diese Daten stehen der
              Öffentlichkeit zur Verfügung und können z.B für wissenschaftliche
              Auswertungen, Studien oder Webapplikationen verwendet werden.
            </Typography>
          </div>
        </section>
        <section
          className={classNames(classes.section, "section-how-it-works")}
        >
          <div className={classes.sectionWorks}>
            <Typography variant="h4" className={classes.sectionElTitle}>
              Upload & Download
              <span className={classes.sectionElDivider} />
            </Typography>
            <Grid container justifyContent="space-around">
              <Grid item xs={12} sm={4}>
                <div className={classes.gridItem}>
                  <CloudUploadIcon style={{ fontSize: 50 }} />
                  <Typography variant="body1" align="center">
                    Baumkatasterdaten können über das{" "}
                    <Link to="/uploadTool">Upload-Tool</Link> veröffentlicht
                    werden.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={classes.gridItem}>
                  <CloudDownloadIcon style={{ fontSize: 50 }} />
                  <Typography variant="body1" align="center">
                    Über den <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_LINK_DATAVIEWER}>BaumCloudViewer</a> können die Daten angesehen und heruntergeladen werden.
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
        <section className={classNames(classes.section, "section-credits")}>
          <div className={classes.sectionCredits}>
            <Typography variant="h4" className={classes.sectionElTitle}>
              Gefördert von
              <span className={classes.sectionElDivider} />
            </Typography>
            <Grid container>
              <Grid item xs={12} md={3}>
                <div className={classes.gridItem}>
                  <img
                    src={LogoBMVI}
                    alt="Logo BMVI"
                    className={classes.gridItemLogo}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <div className={classes.gridItem}>
                  <img
                    src={LogoMeinGruen}
                    alt="Logo Mein Grün"
                    className={classes.gridItemLogo}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <div className={classes.gridItem}>
                  <img
                    src={LogoMFund}
                    alt="Logo mFund"
                    className={classes.gridItemLogo}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <div className={classes.gridItem}>
                  <img
                    src={LogoMundRaub}
                    alt="Logo MundRaub"
                    className={classes.gridItemLogo}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
        <footer className={classNames(classes.footer, "section-copyright")}>
          <div className={classes.footerText}>
            <Typography variant="body2">
              © 2019 Kai Gildhorn & Martin Enzner, all rights reserved.
            </Typography>
          </div>
        </footer>
      </Paper>
    </Container>
  );
};

WelcomeView.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WelcomeView);
