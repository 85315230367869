/**
 * Created by Nicolas Looschen - info@pikobytes.de on 10.05.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

import React, { Component, createRef, PureComponent } from "react";
import propTypes from "prop-types";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { Grid, withStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import classNames from "classnames";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import MappingPreviewIcon from "@material-ui/icons/ViewList";
import CreateMappingIcon from "@material-ui/icons/Shuffle";
import { renderMenuItems, renderMenuItemsWithLookup } from "../../util/util";
import { withTheme } from "../../layouts/withTheme";
import MappingPreview from "../../components/MappingPreview/MappingPreview";
import Notification from "../../moduleAuth/components/Notification/Notification";
import "./MappingView.scss";

import {
  ASK_QUALITY,
  ASK_UNIT,
  DATABASE_FIELDS,
  DATABASE_FIELDS_DISPLAY,
  QUALITY_DISPLAY,
  UOM
} from "../../typedefs/typedefs";
import NavigationButtons from "../../components/NavigationButtons";
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = theme => ({
  buttonContainer: {
    alignSelf: "flex-end",
    padding: `0 ${theme.spacing()}px`,
    position: "relative"
  },
  buttonProgress: {
    color: theme.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  divider: {
    margin: 20
  },
  errorneousInput: {
    color: "red"
  },
  errorneousInputHelper: {
    color: "red",
    fontSize: "0.68rem"
  },
  mappingHeader: {
    margin: 0,
    padding: 0
  },
  mappingList: {
    overflowY: "auto"
  },
  mappingListItem: {
    listStylePosition: "inside",
    height: 70
  },
  mappingListItemContainer: { marginBottom: "calc(0.68rem + 4px)" },
  paper: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    margin: theme.spacing(0, 0, 5, 0)
  },
  paperContainer: {
    height: "95%",
    width: "100%",
    padding: theme.spacing(0, 3),
    marginBottom: theme.spacing(5)
  },
  formControl: {
    width: "100%",
    minWidth: 80
  },
  rows: {
    padding: "20px 0"
  },
  title: {
    marginBottom: 10
  }
});

export function getFilledFieldsFromMapping(mapping) {
  const presentValues = Object.values(mapping).filter(
    entry => entry.target !== -1
  );
  const askQuality = presentValues.filter(entry =>
    ASK_QUALITY.includes(entry.target)
  );
  const askUnit = presentValues.filter(entry =>
    ASK_UNIT.includes(entry.target)
  );
  return { askQuality, askUnit };
}

export function isBetween(x, y, value) {
  return x <= value && y >= value;
}

class MappingView extends Component {
  static propTypes = {
    content: propTypes.array,
    data: propTypes.array,
    handleMappingChange: propTypes.func,
    handleSwitch: propTypes.func,
    handleTestMapping: propTypes.func,
    handleUpload: propTypes.func,
    height: propTypes.number,
    finishStep: propTypes.func,
    loading: propTypes.bool,
    mapping: propTypes.object,
    onResize: propTypes.func,
    receivedMapping: propTypes.array,
    previewType: propTypes.bool
  };

  state = {
    cancelToken: axios.CancelToken.source(),
    didManualSwitch: false,
    mapping: this.props.mapping,
    mappingSubmitted: false,
    mappingTested: false,
    notificate: [],
    mappingComplete: true,
    latLongValid: { valid: true }
  };

  paperRef = createRef();
  previewRef = createRef();

  componentDidMount() {
    const { onResize } = this.props;
    onResize();
    window.addEventListener("resize", onResize);
  }

  componentWillUnmount() {
    const { onResize } = this.props;
    if (!this.state.mappingSubmitted) {
      this.props.updateMapping(this.state.mapping);
    }

    window.removeEventListener("resize", onResize);
  }

  checkLatLong(externalKey, isLat) {
    const { content } = this.props;
    const index = content[0].indexOf(externalKey);
    const [a, b] = isLat ? [46, 57] : [5, 16];

    for (let i = 1; i < content.length; i++) {
      if (!isBetween(a, b, parseFloat(content[i][index]))) {
        return { valid: false, value: content[i][index], row: i };
      }
    }

    return { valid: true };
  }

  /**
   * handle change in uom/quality dropdown of mapping
   * @param headerEntry - corresponding header in mapping object
   * @returns {Function}
   */
  handleSecondaryDropdownChange = headerEntry => event => {
    const { mapping } = this.state;
    const newMapping = Object.assign({}, mapping);
    newMapping[headerEntry][event.target.name] = event.target.value;
    this.setState({ mapping: newMapping }, this.handleMappingPreview);
  };

  /**
   * updates a mapping entry in the internal mapping representation
   * @param {?} externalKey - key in external data schema
   * @param {?} internalKey - key in internal data schema
   */
  handleMappingChange = (externalKey, internalKey) => {
    const { resetStep } = this.props;
    const { cancelToken } = this.state;

    resetStep();

    if (
      internalKey === DATABASE_FIELDS.LAT ||
      internalKey === DATABASE_FIELDS.LNG
    ) {
      const { row, valid, value } = this.checkLatLong(
        externalKey,
        DATABASE_FIELDS.LAT === internalKey
      );
      if (!valid)
        this.setState({
          latLongValid: { valid: false, row: row, value: value }
        });
      else {
        this.setState({ latLongValid: { valid: true } });
      }
    }

    const newMapping = Object.assign({}, this.state.mapping);
    newMapping[externalKey] = {
      target: internalKey,
      quality: "",
      uom: ""
    };

    // cancel preceding requests, in order to avoid overwriting results from subsequent requests
    cancelToken.cancel();
    this.setState(
      {
        cancelToken: axios.CancelToken.source(),
        mapping: newMapping,
        mappingTested: false
      },
      this.handleMappingPreview
    );
  };

  /**
   * checks whether all in the mapping defined quality and unit of measurement fields are set
   * @returns {boolean} true if all fields are set, false otherwise
   */
  checkIfMappingComplete() {
    const { mapping } = this.state;
    const { askQuality, askUnit } = getFilledFieldsFromMapping(mapping);

    return (
      askQuality.filter(entry => entry.quality === "").length === 0 &&
      askUnit.filter(entry => entry.uom === "").length === 0
    );
  }

  isMappingEmpty() {
    const { mapping } = this.state;

    return (
      Object.values(mapping).filter(entry => entry.target !== -1).length === 0
    );
  }

  showNotification() {
    const { mapping } = this.state;
    const { askQuality, askUnit } = getFilledFieldsFromMapping(mapping);

    const notificate = askQuality.concat(askUnit).map(entry => entry.target);

    this.setState({
      notificate,
      mappingComplete: false
    });
  }

  /**
   * when the test mapping button is pressed: fetch the mapping, scroll to the preview
   * and allow to continue to next step
   */
  handleMappingPreview = () => {
    const {
      handleSwitch,
      handleTestMapping,
      finishStep,
      resetStep
    } = this.props;
    const { cancelToken, didManualSwitch, mapping } = this.state;

    if (this.isMappingEmpty()) {
      resetStep();
      handleSwitch(false);
      this.setState({ latLongValid: { valid: true } });
      return;
    }

    if (this.checkIfMappingComplete()) {
      this.setState({ mappingComplete: true });
      handleTestMapping(mapping, cancelToken.token).then(() => {
        // set previewType to mapping Preview
        if (!didManualSwitch) {
          handleSwitch(true);
        }
        finishStep();
      });
    } else {
      this.showNotification();
    }
  };

  /**
   * upload the mapping on "check mapping" button press
   * @param event
   */
  handleMappingSubmit = event => {
    const { handleUpload, finishStep, updateMapping } = this.props;
    const { mapping } = this.state;
    if (this.checkIfMappingComplete()) {
      finishStep();
      updateMapping(mapping);
      handleUpload(mapping, event);
    } else {
      this.showNotification();
    }
  };

  /**
   * render heading for mapping list
   * @returns {*}
   */
  renderMappingHeader() {
    const { classes } = this.props;
    return (
      <List dense component="ul" className={classes.mappingHeader}>
        <Grid container alignItems="center">
          <ListItem component="li" className={classes.mappingListItem} divider>
            <Grid item xs={4}>
              <ListItemText
                primary={<Typography>Feld im Ausgangsschema</Typography>}
              />
            </Grid>
            <Grid item>
              <ListItemText
                primary={<Typography>Feld im Zielschema</Typography>}
              />
            </Grid>
          </ListItem>
        </Grid>
      </List>
    );
  }

  /**
   * render mapping list
   * @returns {*}
   */
  renderMapping() {
    const { classes, content, height } = this.props;
    const { mapping, notificate, mappingComplete } = this.state;
    return (
      <List dense component="ul" style={{ maxHeight: height - 470 }}>
        {content[0].map((headerEntry, index) => (
          <ListItem
            component="li"
            key={`headerEntry_${index}`}
            className={classes.mappingListItem}
            divider
          >
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={4}>
                <ListItemText
                  primary={<Typography>{headerEntry}</Typography>}
                />
              </Grid>
              <Grid item xs className={classes.mappingListItemContainer}>
                <MappingSelect
                  classes={classes}
                  menuEntries={Object.keys(DATABASE_FIELDS).filter(
                    key =>
                      !Object.values(mapping)
                        .map(mappingObject => mappingObject.target)
                        .includes(DATABASE_FIELDS[key])
                  )}
                  selection={mapping[headerEntry].target}
                  handleSelection={this.handleMappingChange}
                  externalKey={headerEntry}
                />
              </Grid>

              {mapping[headerEntry].target !== -1 &&
                ASK_QUALITY.includes(mapping[headerEntry].target) && (
                  <Grid
                    item
                    xs={4}
                    className={
                      mappingComplete ? classes.mappingListItemContainer : ""
                    }
                  >
                    <Grid container spacing={1}>
                      {ASK_UNIT.includes(mapping[headerEntry].target) && (
                        <Grid item xs={6}>
                          <FormControl
                            className={classes.formControl}
                            component="div"
                          >
                            <InputLabel
                              className={
                                notificate.includes(
                                  mapping[headerEntry].target
                                ) && mapping[headerEntry].uom === ""
                                  ? classes.errorneousInput
                                  : ""
                              }
                              htmlFor="uom"
                            >
                              Einheit
                            </InputLabel>

                            <Select
                              fullWidth
                              onChange={this.handleSecondaryDropdownChange(
                                headerEntry
                              )}
                              placeholder="Einheit"
                              value={mapping[headerEntry].uom}
                              inputProps={{ name: "uom", id: "uom" }}
                            >
                              {renderMenuItems(UOM)}
                            </Select>
                            {notificate.includes(mapping[headerEntry].target) &&
                              mapping[headerEntry].uom === "" && (
                                <FormHelperText
                                  className={classes.errorneousInputHelper}
                                  margin="dense"
                                >
                                  Benötigtes Feld
                                </FormHelperText>
                              )}
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs style={{ overflow: "hidden" }}>
                        <FormControl
                          component="div"
                          className={classes.formControl}
                        >
                          <InputLabel
                            className={
                              notificate.includes(
                                mapping[headerEntry].target
                              ) && mapping[headerEntry].quality === ""
                                ? classes.errorneousInput
                                : ""
                            }
                            htmlFor="quality"
                          >
                            Qualität
                          </InputLabel>
                          <Select
                            fullWidth
                            onChange={this.handleSecondaryDropdownChange(
                              headerEntry
                            )}
                            placeholder="Qualität"
                            value={mapping[headerEntry].quality}
                            inputProps={{ name: "quality", id: "quality" }}
                          >
                            {renderMenuItemsWithLookup(QUALITY_DISPLAY)}
                          </Select>
                          {notificate.includes(mapping[headerEntry].target) &&
                            mapping[headerEntry].quality === "" && (
                              <FormHelperText
                                className={classes.errorneousInputHelper}
                                margin="dense"
                              >
                                Benötigtes Feld
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </ListItem>
        ))}
      </List>
    );
  }

  render() {
    const {
      classes,
      content,
      data,
      handleSwitch,
      height,
      loading,
      location,
      history,
      finished,
      previewType
    } = this.props;

    const { latLongValid, mappingComplete } = this.state;

    return (
      <React.Fragment>
        <div className={classes.paperContainer}>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={6}>
              <Paper className={classes.paper} ref={this.paperRef}>
                <Grid container direction="row">
                  {content.length !== 0 && (
                    <React.Fragment>
                      <Grid item xs={12} className={classes.title}>
                        <Typography variant="h4" ref={this.previewRef}>
                          <MappingPreviewIcon />
                          Vorschau
                        </Typography>
                        <Typography variant="subtitle1">
                          Sehen Sie hier Ihre originalen Daten oder Ihre jeweils
                          gemappten Daten.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.title}>
                        <Grid container>
                          <Grid
                            item
                            className={classes.mappingPreviewContainer}
                            style={{ margin: "15px 0" }}
                          >
                            <MappingPreview
                              columnCount={data[0].length}
                              data={data}
                              height={height - 550}
                              rowCount={data.length}
                              maxWidth={
                                this.paperRef.current
                                  ? this.paperRef.current.clientWidth - 48
                                  : 600
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={8} />
                        <Grid item xs={1}>
                          {loading && (
                            <div>
                              <span style={{ marginRight: 5 }}>Lade Daten</span>
                              <CircularProgress size={18} />
                            </div>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className={classes.rows}>
                        <FormGroup
                          className={classNames(
                            classes.formGroup,
                            "bk-upload-previewtoggle"
                          )}
                        >
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid
                              item
                              className={classNames(
                                "bk-upload-tooglelabel",
                                !previewType && "active"
                              )}
                            >
                              Original
                            </Grid>
                            <Grid item>
                              <Switch
                                checked={previewType}
                                onChange={event => {
                                  handleSwitch(event);
                                  this.setState({ didManualSwitch: true });
                                }}
                                value="previewType"
                              />
                            </Grid>
                            <Grid
                              item
                              className={classNames(
                                "bk-upload-tooglelabel",
                                previewType && "active"
                              )}
                            >
                              Preview
                            </Grid>
                          </Grid>
                        </FormGroup>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Grid container direction="row">
                  {content.length !== 0 && (
                    <React.Fragment>
                      <Grid item xs={12} className={classes.title}>
                        <Typography variant="h4">
                          <CreateMappingIcon />
                          Mapping
                        </Typography>
                        <Typography variant="subtitle1">
                          Ordnen Sie die Felder Ihrer hinaufgeladenen Daten den
                          Feldern des Zielschemas zu.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.mappingHeader}>
                        {this.renderMappingHeader()}
                      </Grid>
                      <Grid item xs={12} className={classes.mappingList}>
                        {this.renderMapping()}
                      </Grid>
                    </React.Fragment>
                  )}
                  {!latLongValid.valid && (
                    <Notification
                      style={{
                        position: "absolute",
                        top: height - 240
                      }}
                      message={`Die ausgewählte Spalte für Geographische Breite/Länge enthält den invaliden Wert
                         "${
                           latLongValid.value === "" ? " " : latLongValid.value
                         }" in Zeile ${latLongValid.row}.`}
                      onClose={() =>
                        this.setState({
                          latLongValid: { valid: true }
                        })
                      }
                      variant="error"
                    />
                  )}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>

        <NavigationButtons
          excludeBack
          overwriteHelperText={!mappingComplete}
          helperText="Bitte die eingefärbten Felder ausfüllen"
          customHandler={this.handleMappingSubmit}
          history={history}
          location={location}
          finished={finished}
        />
      </React.Fragment>
    );
  }
}

class MappingSelect extends PureComponent {
  static propTypes = {
    menuEntries: propTypes.array,
    externalKey: propTypes.string,
    handleSelection: propTypes.func,
    selection: propTypes.number
  };

  handleSelection = event => {
    this.props.handleSelection(this.props.externalKey, event.target.value);
  };

  render() {
    const { classes, externalKey, menuEntries, selection } = this.props;
    return (
      <FormControl component="div" className={classes.formControl}>
        <InputLabel htmlFor={`mapping-${externalKey}`}>
          Bitte auswählen
        </InputLabel>
        <Select
          value={selection === -1 ? "" : selection}
          renderValue={value => DATABASE_FIELDS_DISPLAY[value]}
          onChange={this.handleSelection}
          inputProps={{
            name: `mapping-${externalKey}`,
            id: `mapping-${externalKey}`
          }}
        >
          <MenuItem button={true} component="div" key="None" value={-1}>
            Keine Zuordnung
          </MenuItem>
          {menuEntries.map(key => (
            <MenuItem
              button={true}
              component="div"
              key={DATABASE_FIELDS[key]}
              value={DATABASE_FIELDS[key]}
            >
              {DATABASE_FIELDS_DISPLAY[DATABASE_FIELDS[key]]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

export default withTheme(withStyles(styles)(MappingView));
