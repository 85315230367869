/**
 * Created by Nicolas Looschen - info@pikobytes.de on 24.05.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, { PureComponent } from "react";
import memoize from "memoize-one";
import { VariableSizeGrid as Table } from "react-window";
import TableCell from "@material-ui/core/TableCell";
import propTypes from "prop-types";

import { sumTill } from "../../util/util";

export const MIN_CELL_WIDTH = 30;

const Header = props => {
  const header = ["", ...props.data];
  return (
    <div
      ref={props.headerRef}
      style={{
        width: props.width - 15,
        position: "relative",
        height: 20,
        overflowX: "hidden"
      }}
    >
      {header.map((item, index) => {
        const left = sumTill(props.columnWidths, index);
        const width = props.columnWidths[index];
        return (
          <TableCell
            component="div"
            style={{
              padding: 0,
              textAlign: "center",
              width: Number.isNaN(width) ? 0 : width,
              position: "absolute",
              top: 0,
              height: "20px",
              left: Number.isNaN(left) ? 0 : left
            }}
            variant="head"
            key={index}
          >
            {item}
          </TableCell>
        );
      })}
    </div>
  );
};

class CellRenderer extends PureComponent {
  static propTypes = {
    columnIndex: propTypes.number,
    data: propTypes.any,
    rowIndex: propTypes.number,
    style: propTypes.object
  };
  render() {
    const { columnIndex, data, rowIndex, style } = this.props;
    return (
      <TableCell
        style={Object.assign({}, style, {
          padding: "5px 5px 5px 5px",
          textAlign: "center"
        })}
        component="div"
        variant={"body"}
      >
        {
          // use row numbers on first column and else data
          //increment row number to offset header
          //decrement columnIndex to offset the rowNumber column
        }
        {columnIndex === 0
          ? [rowIndex + 1]
          : data[rowIndex + 1][columnIndex - 1]}
      </TableCell>
    );
  }
}

export default class MappingPreview extends PureComponent {
  static propTypes = {
    columnCount: propTypes.number,
    data: propTypes.array, // immutable list
    height: propTypes.number,
    rowCount: propTypes.number
  };

  state = {
    offsetLeft: 0
  };

  tableRef = React.createRef();
  headerRef = React.createRef();

  memoizedWidths = memoize((data, maxWidth) =>
    MappingPreview.calculateWidths(data, maxWidth)
  );

  componentDidUpdate(prevProps) {
    const { data, columnCount, maxWidth, rowCount } = this.props;
    if (
      data !== prevProps.data ||
      columnCount !== prevProps.columnCount ||
      maxWidth !== prevProps.maxWidth ||
      rowCount !== prevProps.rowCount
    ) {
      if (this.tableRef.current !== null) {
        this.tableRef.current.resetAfterColumnIndex(0);
      }
    }
  }

  /**
   * calculate max columnWidths from specified csv representation
   * @param data
   * @returns {*}
   */
  static calculateWidths(data, maxWidth) {
    const widths = data.map(x => x.map(y => (y !== undefined ? y.length : 1)));

    let columnWidths = widths[0];
    for (let row = 0; row < widths.length; row++) {
      for (let column = 0; column < widths[row].length; column++) {
        const current = widths[row][column];
        if (current > columnWidths[column]) {
          columnWidths[column] = current;
        }
      }
    }

    const resultWidths = columnWidths.map(x => Math.max(x * 9, MIN_CELL_WIDTH));

    const completeWidth = resultWidths.reduce((a, b) => a + b, 0);
    return completeWidth < maxWidth
      ? resultWidths.map(
          element =>
            element +
            Math.floor((maxWidth - completeWidth) / resultWidths.length)
        )
      : resultWidths;
  }

  render() {
    const { columnCount, data, height, maxWidth, rowCount } = this.props;
    const columnWidths = [
      data.length.toString().length * 8,
      ...this.memoizedWidths(data, maxWidth)
    ];

    // either use maxWidth or just use the needed space -> scrollbar gets placed on width, 15 to offset scrollbad width

    return (
      <React.Fragment>
        {columnWidths.size !== 0 && (
          <React.Fragment>
            <Header
              data={data[0]}
              columnWidths={columnWidths}
              headerRef={this.headerRef}
              width={maxWidth}
            />
            <Table
              onScroll={object =>
                (this.headerRef.current.scrollLeft = object.scrollLeft)
              }
              ref={this.tableRef}
              itemData={data}
              columnCount={columnCount + 1} // -> plus row index column
              columnWidth={index => columnWidths[index]}
              height={Math.max(height, 300)} // window height - other objects, minimum height 300
              rowCount={rowCount - 1} // -> minus header
              rowHeight={() => Math.max((height - 590) / 10, 50)}
              width={maxWidth}
            >
              {CellRenderer}
            </Table>
            <p
              style={{
                float: "right",
                fontStyle: "italic",
                margin: "5px 20px 0 0"
              }}
            >
              {rowCount - 1} Zeilen
            </p>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
