/**
 * Created by Nicolas Looschen - info@pikobytes.de on 10.05.19.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import TextField from "@material-ui/core/TextField";
import propTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import classNames from "classnames";
import axios from "axios";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import MetadataIcon from "@material-ui/icons/Assignment";
import UploadInProgressIcon from "@material-ui/icons/Autorenew";
import RemoveFileIcon from "@material-ui/icons/Delete";
import UploadPreviewIcon from "@material-ui/icons/ViewList";
import { withTheme } from "../../layouts/withTheme";
import { Grid, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { renderMenuItems } from "../../util/util";

import "./UploadView.scss";
import {
  FILE_DELIMITER,
  FILE_ENCODINGS,
  LICENSES
} from "../../typedefs/typedefs";
import MappingPreview from "../../components/MappingPreview/MappingPreview";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import "moment/locale/de";
import NavigationButtons from "../../components/NavigationButtons";

const styles = theme => ({
  buttonContainer: {
    alignSelf: "flex-end",
    position: "relative"
  },
  buttonProgress: {
    color: theme.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  input: {
    height: "100%",
    width: "100%",
    display: "none"
  },
  dropBoxLabel: {
    color: "rgba(0,0,0,0.3)"
  },
  loadingFeedback: {
    textAlign: "right"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
    margin: theme.spacing(0, 0, 5, 0)
  },
  paperContainer: {
    height: "95%",
    width: "100%",
    padding: theme.spacing(0, 3)
  },
  formControl: {
    width: "100%",
    minWidth: 120
  }
});

class UploadView extends Component {
  static propTypes = {
    actuality: propTypes.oneOfType([
      propTypes.instanceOf(moment),
      propTypes.number
    ]),
    clearData: propTypes.func,
    file: propTypes.any, // file object
    fileDelimiter: propTypes.oneOfType([propTypes.number, propTypes.string]),
    fileEncoding: propTypes.oneOfType([propTypes.number, propTypes.string]),
    fileUploaded: propTypes.bool,
    finished: propTypes.bool,
    isParsing: propTypes.bool,
    license: propTypes.oneOfType([propTypes.number, propTypes.string]),
    licenseName: propTypes.string,
    updateSetting: propTypes.func,
    uniqueHeaders: propTypes.bool.isRequired
  };

  paperRef = React.createRef();

  // componentDidMount() {
  //   checkPresenceOnServer("csv").then(() => this.props.finishStep());
  // }

  state = {
    cancelSource: axios.CancelToken.source(),
    dropState: "",
    loading: false,
    width: 1000
  };

  componentDidMount() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  }

  onResize = () => {
    if (this.paperRef.current) {
      this.setState({ width: this.paperRef.current.clientWidth });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  componentDidUpdate(prevProps) {
    const {
      fileUploaded,
      handleUpload,
      isParsing,
      uniqueHeaders,
      updateSetting
    } = this.props;
    const { cancelSource, loading } = this.state;

    if (
      !isParsing &&
      prevProps.isParsing &&
      uniqueHeaders &&
      !loading &&
      !fileUploaded
    ) {
      this.setState({ loading: true }, () =>
        handleUpload(() => {
          this.setState({
            cancelToken: axios.CancelToken.source(),
            loading: false
          });
          updateSetting({ fileUploaded: true }, true);
        }, cancelSource.token)
      );
    }
  }

  /**
   * handle changes of dropdown menu (license)
   * @param event
   */
  handleChange = doNotUpdate => event => {
    const { updateSetting } = this.props;
    updateSetting({ [event.target.name]: event.target.value }, doNotUpdate);
  };

  /**
   * handle date selection
   * @param date - moment object representing the chosen date
   * @returns {*}
   */
  handleDateSelection = date => this.props.updateSetting({ actuality: date });

  /**
   * handle drag over dropBox
   * @param event
   */
  handleDragOver = event => {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
    this.setState({ dropState: "dragover" });
  };

  handleFileChange = () => {
    const { clearData, updateSetting } = this.props;
    const { cancelSource } = this.state;

    this.setState({ dropState: "" });
    clearData();
    updateSetting({ uniqueHeaders: true }, true);
    //cancel currently running upload progress
    cancelSource.cancel();
    this.setState({
      cancelSource: axios.CancelToken.source(),
      dropState: "",
      loading: false
    });
  };

  /**
   * handle file drop on dropBox
   * @param event
   */
  handleFileDragAndDrop = event => {
    const { updateSetting } = this.props;
    event.stopPropagation();
    event.preventDefault();
    if (event.dataTransfer.files[0].name.endsWith(".csv")) {
      updateSetting({ file: event.dataTransfer.files[0] });
    } else {
      this.setState({ dropState: "forbidden" });
    }
  };

  /**
   * handle manual file input and open dialog to choose file
   * @param event
   */
  handleFileInput = event => {
    const { updateSetting } = this.props;
    if (event.target.files[0].name.endsWith(".csv")) {
      updateSetting({ file: event.target.files[0] });
    } else {
      this.setState({ dropState: "forbidden" });
    }
  };

  render() {
    const {
      actuality,
      content,
      classes,
      doubleHeaders,
      file,
      finished,
      fileDelimiter,
      fileEncoding,
      history,
      isParsing,
      license,
      licenseName,
      licenseType,
      location,
      uniqueHeaders
    } = this.props;

    const { dropState, loading, width } = this.state;
    return (
      <React.Fragment>
        <div className={classes.paperContainer}>
          <Paper
            className={classNames(classes.paper, "bk-upload")}
            ref={this.paperRef}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={3}
            >
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {content.length !== 0 ? (
                      <React.Fragment>
                        <Grid item xs={12}>
                          <Typography variant="h4">
                            <UploadPreviewIcon />
                            Vorschau Datensatz
                          </Typography>
                          <Typography variant="subtitle1">
                            Hier sehen Sie die gerade hochgeladenen Daten.
                          </Typography>
                        </Grid>
                        <Grid item style={{ margin: "15px 0" }}>
                          {loading && (
                            <div className={classes.loadingFeedback}>
                              Daten werden hochgeladen{" "}
                              <CircularProgress
                                size={20}
                                className={classes.progress}
                              />
                            </div>
                          )}
                          <MappingPreview
                            columnCount={content[0].length}
                            data={content}
                            height={400}
                            rowCount={Math.min(11, content.length)}
                            maxWidth={width - 48}
                          />
                        </Grid>
                      </React.Fragment>
                    ) : isParsing ? (
                      <React.Fragment>
                        <Grid item xs={12}>
                          <Typography variant="h4">
                            <UploadInProgressIcon />
                            Vorschau wird erstellt
                          </Typography>
                          <Typography variant="subtitle1">
                            Die von Ihnen angegebenen Daten werden gerade
                            prozessiert. Bitte ein wenig Geduld.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ margin: "15px 0" }}>
                          <div
                            style={{
                              backgroundColor: "rgba(0,0,0,0.25)",
                              height: 400,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: width - 48
                            }}
                          >
                            <CircularProgress
                              size={100}
                              className={classes.progress}
                            />
                          </div>
                        </Grid>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Grid item xs={12}>
                          <Typography variant="h4" className={classes.title}>
                            <CloudUploadIcon />
                            Upload Datensatz
                          </Typography>
                          <Typography variant="subtitle1">
                            Fügen Sie an dieser Stelle die gewünschte Datei
                            hinzu und passen ggf. Einstellung an.
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          alignContent="center"
                          justifyContent="center"
                          container
                          className={`dropBox ${
                            file !== undefined
                              ? uniqueHeaders
                                ? "dropped"
                                : "forbidden"
                              : ""
                          } ${dropState} ${!uniqueHeaders ? "forbidden" : ""}`}
                          id="dropBox"
                          onDragOver={this.handleDragOver}
                          onDrop={this.handleFileDragAndDrop}
                          onDragLeave={() => this.setState({ dropState: "" })}
                          onClick={() =>
                            document
                              .getElementById("outlined-button-file")
                              .click()
                          }
                        >
                          {file !== undefined ? (
                            !uniqueHeaders ? (
                              <div className="bk-upload-error">
                                <Typography variant="h6">
                                  {" "}
                                  Uneindeutige Spaltennamen
                                </Typography>
                                <Typography variant="body1">
                                  In der ausgewählten Datei kommen Spaltennamen
                                  wiederholt vor. Bitte laden Sie eine Datei mit
                                  eindeutigen Spaltennamen hoch. Folgende
                                  Spalten sind betroffen:{" "}
                                  {doubleHeaders.join(", ")}
                                </Typography>
                              </div>
                            ) : (
                              <div>{file.name}</div>
                            )
                          ) : dropState === "forbidden" ? (
                            <div className="bk-upload-error">
                              <Typography variant="h6">
                                Falscher Dateityp
                              </Typography>
                              <Typography variant="body1">
                                Bitte versuchen Sie es erneut mit einem
                                passenden Datei-Format. Mehr Informationen dazu
                                finden sie in den FAQs.
                              </Typography>
                            </div>
                          ) : (
                            <div className="bk-upload-here">
                              <Typography variant="h6">
                                Datei hochladen
                              </Typography>
                              <Typography variant="body1">
                                Datei zum Hochladen hier ablegen oder&nbsp;
                                <Button
                                  size="small"
                                  variant="outlined"
                                  type="submit"
                                >
                                  Hier klicken
                                </Button>
                              </Typography>
                            </div>
                          )}
                          <Grid item id="no-block">
                            <input
                              onChange={this.handleFileInput}
                              accept="text/csv"
                              className={classes.input}
                              id="outlined-button-file"
                              type="file"
                            />
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    )}
                    <Grid item xs={12}>
                      <Grid container alignItems="flex-end" spacing={4}>
                        <Grid item xs={3}>
                          <FormControl
                            component="div"
                            className={classes.formControl}
                          >
                            <InputLabel htmlFor="fileEncoding">
                              Kodierung
                            </InputLabel>
                            <Select
                              value={fileEncoding}
                              onChange={this.handleChange(false)}
                              inputProps={{
                                name: "fileEncoding",
                                id: "fileEncoding"
                              }}
                            >
                              {renderMenuItems(FILE_ENCODINGS)}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                          <FormControl
                            component="div"
                            className={classes.formControl}
                          >
                            <InputLabel htmlFor="fileDelimiter">
                              Trennzeichen
                            </InputLabel>

                            <Select
                              value={fileDelimiter}
                              onChange={this.handleChange(false)}
                              name="fileDelimiter"
                              inputProps={{
                                id: "fileDelimiter"
                              }}
                            >
                              {renderMenuItems(FILE_DELIMITER)}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={3} />
                        <Grid item xs={3} className={classes.buttonContainer}>
                          {(content.length !== 0 || !uniqueHeaders) && (
                            <Button
                              fullWidth
                              onClick={this.handleFileChange}
                              variant="outlined"
                              type="submit"
                              className={classes.button}
                            >
                              <RemoveFileIcon />
                              Datei verwerfen
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Paper>
          <Paper className={classNames(classes.paper, "bk-metadata")}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={4}
            >
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid item xs={12}>
                  <Typography variant="h4" component="h4">
                    <MetadataIcon />
                    Metadaten
                  </Typography>
                  <Typography variant="subtitle1">
                    Wählen Sie eine Lizenz sowie das Erhebungsdatum aus.
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <form className={classes.form}>
                    <Grid container direction="row" spacing={1}>
                      <Grid
                        item
                        xs={
                          license !== ""
                            ? license === LICENSES.Andere
                              ? 4
                              : 6
                            : 12
                        }
                      >
                        <FormControl
                          component="div"
                          className={classes.formControl}
                        >
                          <InputLabel htmlFor="license">Lizenz</InputLabel>
                          <Select
                            fullWidth
                            value={license}
                            onChange={this.handleChange(true)}
                            inputProps={{
                              name: "license",
                              id: "license"
                            }}
                          >
                            {renderMenuItems(LICENSES)}
                          </Select>
                        </FormControl>
                      </Grid>

                      {license === LICENSES.Andere && (
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            value={licenseType}
                            onChange={this.handleChange(true)}
                            inputProps={{
                              id: "licenseType",
                              name: "licenseType"
                            }}
                            label="Lizenzbezeichnung"
                          />
                        </Grid>
                      )}
                      {license !== "" && (
                        <Grid item xs={license === LICENSES.Andere ? 4 : 6}>
                          <TextField
                            fullWidth
                            inputProps={{
                              id: "licenseName",
                              name: "licenseName"
                            }}
                            label="Zu nennender Name"
                            onChange={this.handleChange(true)}
                            value={licenseName}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </form>
                </Grid>
                <Grid item xs={2}>
                  <KeyboardDatePicker
                    id="bk-date-pick"
                    disableFuture
                    onChange={this.handleDateSelection}
                    format="DD-MM-YYYY"
                    value={actuality}
                    label="Datum"
                    invalidDateMessage="Das eingegebene Datum hat ein falsches Format."
                    style={{ width: "100%" }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Paper>
        </div>
        <NavigationButtons
          loading={loading}
          history={history}
          location={location}
          finished={finished}
        />
      </React.Fragment>
    );
  }
}

export default withTheme(withStyles(styles)(UploadView));
